import { createContext } from 'react'
import { AuthorizationUseCases } from '../useCase/authorization/authorization';
import { UserUseCases } from '../useCase/users/user';
import { CalcParamsUseCases } from '../useCase/calcparams/calcparams';
import { PaymentUseCases } from '../useCase/payment/payment';
import { ReportUseCases } from '../useCase/report/report';
import { TabelUseCases } from '../useCase/tabel/report';
 

interface UseCasesContextInterface {
    authUseCase: AuthorizationUseCases;
    userUseCase: UserUseCases;
    calcParamsUseCase: CalcParamsUseCases,
    paymentsUseCase: PaymentUseCases,
    reportUseCase: ReportUseCases,
    tabelUseCase: TabelUseCases;
}

export const UseCasesContext = createContext<UseCasesContextInterface | null>(null)