import { OperatorWorkTimesRepository } from "../../repository/api/operatorworktime/operatorworktime";
import { actionCreators } from "../../state";

export class TabelUseCases {
    private api: OperatorWorkTimesRepository;
    private stateActions: typeof actionCreators;

    constructor(api: OperatorWorkTimesRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadMonthTabel = async (month: number, year: number, rgs: string[], operatorLogins: string[]) => {
        this.stateActions.SendLeaderGroupListRequest()
        let response = await this.api.ReadMonthTabel(month, year, rgs, operatorLogins)
        if (response instanceof Error) {
            this.stateActions.LeaderGroupListError(response.message)
            return
        } else {
            this.stateActions.LeaderGroupListSuccess(response)
        }
        return response
    }

    public ReadMonthTabelXlsx = async (month: number, year: number, rgs: string[], operatorLogins: string[]) => {
        let response = await this.api.ReadMonthTabelXlsx(month, year, rgs, operatorLogins)
        return response
    }

    public CreateWorkTimeCorrection = async (operatorWorkTimeId: string, factWorkedSeconds: number, postWorkTimeSeconds: number, comment: string, callback?: ()=>void) => {
        let response = await this.api.CreateOperatorCorrection(operatorWorkTimeId, factWorkedSeconds, postWorkTimeSeconds, comment)
        if (response instanceof Error) {

        } else {
            this.stateActions.LeaderGroupUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }

        return
    }

    public UpdateWorkTimeCorrection = async (id: UniqueId, operatorWorkTimeId: string, factWorkedSeconds: number, postWorkTimeSeconds: number, comment: string, callback?: ()=>void) => {
        let response = await this.api.UpdateOperatorCorrection(id, operatorWorkTimeId, factWorkedSeconds, postWorkTimeSeconds, comment)
        if (response instanceof Error) {

        } else {
            this.stateActions.LeaderGroupUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }

        return
    }

    public ApproveWorkTimeCorrection = async (correctiondId: UniqueId, callback?: ()=>void) => {
        let response = await this.api.ApproveOperatorCorrection(correctiondId)
        if (response instanceof Error) {

        } else {
            this.stateActions.LeaderGroupUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }

        return
    
    }

    public DeclineWorkTimeCorrection = async (correctiondId: UniqueId, callback?: ()=>void) => {
        let response = await this.api.DeclineOperatorCorrection(correctiondId)
        if (response instanceof Error) {

        } else {
            this.stateActions.LeaderGroupUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }

        return
    
    }

}