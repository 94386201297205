import { OperatorConsalidatedStat } from "./operator_consalidated_stat";

export class LeaderGroup {
    private leaderName: string; 
    private operatorsTabel: OperatorConsalidatedStat[];


    constructor( leaderName: string, operatorsTabel: OperatorConsalidatedStat[]) {
        this.leaderName = leaderName; 
        this.operatorsTabel = operatorsTabel;
    }

    public getLeaderName = (): string => {
        return this.leaderName
    }

    public getOperatorsTabel(): OperatorConsalidatedStat[] {
        return this.operatorsTabel;
    }
}

