import { BrandReddit, Cash, ChartDots3, CurrencyRubel, Logout, Settings, TableAlias, } from 'tabler-icons-react';

export const Items = [

    {
        title: "Зарплата операторов",
        isAdmin: false,
        icon: <CurrencyRubel size={24} className="menu-icon"  />,
        href: "/zp",
    },

    {
        title: "Зарплата наставников и РГ",
        isAdmin: false,
        isAdminAndHeadOfContact: true,
        icon: <Cash size={24} className="menu-icon"  />,
        href: "/heads-zp",   
    },

    {
        title: "Табель рабочего времени операторов",
        isAdmin: false,
        icon: <TableAlias size={24} className="menu-icon"  />,
        href: "/tabel",
    },


    {
        title: "Условия расчета",
        isAdmin: true,
        icon: <ChartDots3 size={24} className="menu-icon"  />,
        href: "/calc-params",
    },
    
    {
        title: "Учетные записи",
        isAdmin: true,
        icon: <BrandReddit size={24} className="menu-icon"  />,
        href: "/user",
    },

    {
        title: "Настройки",
        isAdmin: true,
        icon: <Settings size={24} className="menu-icon"  />,
        href: "/settings",
    },

    
    
    
    {
        title: "Выход",
        icon: <Logout size={24} className="menu-icon" />,
        href: "/exit",
    },

]