import { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../context/useCases"
import { FiledType, Form } from "../../components/form"
import { Payment } from "../../../domain/payment/payment"


export const ImportModal = (props: { close: () => void, closeUpdate: () => void }) => {

    let useCases = useContext(UseCasesContext)

    const [form, setForm] = useState<
        {
            file: File | null,

        }>(
            {
                file: null,
            }
        )

    const [error, setError] = useState("")

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (form.file) {
            let response = await useCases?.paymentsUseCase.ImportBonusesFromXlsx(form.file)
            if (response instanceof Error) {
                setError(response.message)
            } else {
                props.closeUpdate()
            }
        }
    }

    

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }


    return <div className="modal fade show" id="modalCenter" style={{ display: "block" }} aria-modal="true" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="modalCenterTitle">Импорт премий</h5>

                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { props.close() }}></button>
                </div>
                
                <div className="modal-body">
                    {error!== ""?<div className="alert alert-danger" role="alert">Ошибка: {error}</div>:<></>}
                    <Form
                        state={{
                            loading: false,
                            error: "",
                        }}

                        submit={submit}

                        fields={[
                            {
                                name: "file",
                                title: "Файл для импорта",
                                placeholder: "Файл для импорта",
                                value: form.file,
                                type: FiledType.FileSingle,
                            },

                        ]}
                        btnSmall={true}
                        submitBtnTitle={"Сохранить"}
                        updateForm={updateForm}
                    />
                </div>

            </div>
        </div>
    </div>
}