import axios from "axios";
import { CalcParams } from "../../../domain/calcparams/calcparams";
import { CalcParamsInterface } from "../../../useCase/calcparams/adapters/interface";
import { CalcparamsApi, CalcparamsCalcParamsResponse, Configuration } from "../generated";

export class CalcParamssRepository implements CalcParamsInterface {
    private service: CalcparamsApi;

    constructor() {
        this.service = new CalcparamsApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }
    
    async CreateCalcParams(from: Date, study: number, profi: number, middle: number, junior: number): Promise<CalcParams | Error> {
        try {
            let response = await this.service.apiCalcparamsPost({start: from.toISOString(), study: study, profi: profi, middle: middle, junior: junior }, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return this.toDomainCalcParams(response.data!)
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadActualCalcParams(): Promise<CalcParams | Error> {
        try {
            let response = await this.service.apiCalcparamsGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return this.toDomainCalcParams(response.data!)
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    toDomainCalcParams = (input: CalcparamsCalcParamsResponse) => {
        return new CalcParams(input.id!, new Date(input.start!), input.junior!, input.middle!, input.profi!, input.study!, new Date(input.created_at!), new Date(input.modified_at!))
    }

}