import { Dispatch } from "react"
import { LeaderGroupActionTypes } from "../action-types";
import { LeaderGroupActions } from "../actions"
import { LeaderGroup } from "../../domain/operatorworktime/leader_group";

export const SendLeaderGroupListRequest = () => {
    return async (dispatch: Dispatch<LeaderGroupActions>) => {
        dispatch({
            type: LeaderGroupActionTypes.LEADER_GROUP_REQUEST_SEND,
          });
    }
}

export const LeaderGroupListSuccess = (list: LeaderGroup[]) => {
    return async (dispatch: Dispatch<LeaderGroupActions>) => {
        dispatch({
            type: LeaderGroupActionTypes.LEADER_GROUP_SUCCESS,
            payload: list,
        });
    }
}

export const LeaderGroupListError = (error:string) => {
    return async (dispatch: Dispatch<LeaderGroupActions>) => {
        dispatch({
            type: LeaderGroupActionTypes.LEADER_GROUP_ERROR,
            payload: error,
        });
    }
}

export const LeaderGroupCreateRequest = () => {
    return async (dispatch: Dispatch<LeaderGroupActions>) => {
        dispatch({
            type: LeaderGroupActionTypes.LEADER_GROUP_CREATE_REQUEST,
          });
    }
}

export const LeaderGroupCreateSuccess = (operatorworktime: LeaderGroup) => {
    return async (dispatch: Dispatch<LeaderGroupActions>) => {
        dispatch({
            type: LeaderGroupActionTypes.LEADER_GROUP_CREATE_SUCCESS,
            payload: operatorworktime,
          });
    }
}

export const LeaderGroupCreateError = (message: string) => {
    return async (dispatch: Dispatch<LeaderGroupActions>) => {
        dispatch({
            type: LeaderGroupActionTypes.LEADER_GROUP_CREATE_ERROR,
            payload: message,
          });
    }
}


export const LeaderGroupUpdateRequest = () => {
    return async (dispatch: Dispatch<LeaderGroupActions>) => {
        dispatch({
            type: LeaderGroupActionTypes.LEADER_GROUP_UPDATE_REQUEST,
          });
    }
}

export const LeaderGroupUpdateSuccess = (category: LeaderGroup) => {
    return async (dispatch: Dispatch<LeaderGroupActions>) => {
        dispatch({
            type: LeaderGroupActionTypes.LEADER_GROUP_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const LeaderGroupUpdateError = (message: string) => {
    return async (dispatch: Dispatch<LeaderGroupActions>) => {
        dispatch({
            type: LeaderGroupActionTypes.LEADER_GROUP_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const LeaderGroupDeleteRequest = () => {
    return async (dispatch: Dispatch<LeaderGroupActions>) => {
        dispatch({
            type: LeaderGroupActionTypes.LEADER_GROUP_DELETE_REQUEST,
          });
    }
}

export const LeaderGroupDeleteSuccess = (id: UniqueId) => {
    return async (dispatch: Dispatch<LeaderGroupActions>) => {
        dispatch({
            type: LeaderGroupActionTypes.LEADER_GROUP_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const LeaderGroupDeleteError = (message: string) => {
    return async (dispatch: Dispatch<LeaderGroupActions>) => {
        dispatch({
            type: LeaderGroupActionTypes.LEADER_GROUP_DELETE_ERROR,
            payload: message,
          });
    }
}