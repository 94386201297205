import { Auth } from "../repository/api/auth/auth"
import { CalcParamssRepository } from "../repository/api/calcparams/calcparams"
import { OperatorWorkTimesRepository } from "../repository/api/operatorworktime/operatorworktime"
import { PaymentsRepository } from "../repository/api/payment/payment"
import { ReportsRepository } from "../repository/api/report/report"
import { UsersRepository } from "../repository/api/user/user"
 
import { AuthorizationUseCases } from "../useCase/authorization/authorization"
import { CalcParamsUseCases } from "../useCase/calcparams/calcparams"
import { PaymentUseCases } from "../useCase/payment/payment"
import { ReportUseCases } from "../useCase/report/report"
import { TabelUseCases } from "../useCase/tabel/report"
 
import { UserUseCases } from "../useCase/users/user"


export const useUseCases = (actions:any) => {
    const authUseCase = new AuthorizationUseCases(new Auth(), actions)
    const userUseCase =  new UserUseCases(new UsersRepository(), actions)
    const calcParamsUseCase = new CalcParamsUseCases(new CalcParamssRepository(), actions)
    const paymentsUseCase = new PaymentUseCases(new PaymentsRepository(), actions)
    const reportUseCase = new ReportUseCases(new ReportsRepository(), actions)
    const tabelUseCase = new TabelUseCases(new OperatorWorkTimesRepository(), actions)
    

    return {authUseCase, userUseCase, calcParamsUseCase, paymentsUseCase, reportUseCase, tabelUseCase}
}