export enum CalcParamsActionTypes {
    CALCPARAMS_REQUEST_SEND = 'calcparams_request_send',
    CALCPARAMS_ERROR = 'calcparams_error',
    CALCPARAMS_SUCCESS = 'calcparams_success',
    
    CALCPARAMS_CREATE_REQUEST = 'calcparams_create_request',
    CALCPARAMS_CREATE_SUCCESS = 'calcparams_create_success',
    CALCPARAMS_CREATE_ERROR = 'calcparams_create_error',

    CALCPARAMS_DELETE_REQUEST = 'calcparams_delete_request',
    CALCPARAMS_DELETE_SUCCESS = 'calcparams_delete_success',
    CALCPARAMS_DELETE_ERROR = 'calcparams_delete_error',

    CALCPARAMS_UPDATE_REQUEST = 'calcparams_update_request',
    CALCPARAMS_UPDATE_SUCCESS = 'calcparams_update_success',
    CALCPARAMS_UPDATE_ERROR = 'calcparams_update_error',
}