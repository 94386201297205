import { CalcParamssRepository } from "../../repository/api/calcparams/calcparams";
import { actionCreators } from "../../state";

export class CalcParamsUseCases {
    private api: CalcParamssRepository;
    private stateActions: typeof actionCreators;

    constructor(api: CalcParamssRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadActualCalcParams = async () => {
        this.stateActions.SendCalcParamsListRequest()
        let response = await this.api.ReadActualCalcParams()
        return response
    }

    public CreateCalcParams = async (from: Date, study: number, profi: number, middle: number, junior: number, callback?: ()=>void) => {
        this.stateActions.CalcParamsCreateRequest()
        let response = await this.api.CreateCalcParams(from, study, profi, middle, junior)
        if (response instanceof Error) {
            this.stateActions.CalcParamsCreateError(response.message)
            return
        } else {
            this.stateActions.CalcParamsCreateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

}