import { Dispatch } from "react"
import { OperatorActionTypes } from "../action-types";
import { OperatorActions } from "../actions"
import { Operator } from "../../domain/operator/operator";

export const SendOperatorListRequest = () => {
    return async (dispatch: Dispatch<OperatorActions>) => {
        dispatch({
            type: OperatorActionTypes.OPERATOR_REQUEST_SEND,
          });
    }
}

export const OperatorListSuccess = (list: Operator[]) => {
    return async (dispatch: Dispatch<OperatorActions>) => {
        dispatch({
            type: OperatorActionTypes.OPERATOR_SUCCESS,
            payload: list,
        });
    }
}

export const OperatorListError = (error:string) => {
    return async (dispatch: Dispatch<OperatorActions>) => {
        dispatch({
            type: OperatorActionTypes.OPERATOR_ERROR,
            payload: error,
        });
    }
}

export const OperatorCreateRequest = () => {
    return async (dispatch: Dispatch<OperatorActions>) => {
        dispatch({
            type: OperatorActionTypes.OPERATOR_CREATE_REQUEST,
          });
    }
}

export const OperatorCreateSuccess = (operator: Operator) => {
    return async (dispatch: Dispatch<OperatorActions>) => {
        dispatch({
            type: OperatorActionTypes.OPERATOR_CREATE_SUCCESS,
            payload: operator,
          });
    }
}

export const OperatorCreateError = (message: string) => {
    return async (dispatch: Dispatch<OperatorActions>) => {
        dispatch({
            type: OperatorActionTypes.OPERATOR_CREATE_ERROR,
            payload: message,
          });
    }
}


export const OperatorUpdateRequest = () => {
    return async (dispatch: Dispatch<OperatorActions>) => {
        dispatch({
            type: OperatorActionTypes.OPERATOR_UPDATE_REQUEST,
          });
    }
}

export const OperatorUpdateSuccess = (category: Operator) => {
    return async (dispatch: Dispatch<OperatorActions>) => {
        dispatch({
            type: OperatorActionTypes.OPERATOR_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const OperatorUpdateError = (message: string) => {
    return async (dispatch: Dispatch<OperatorActions>) => {
        dispatch({
            type: OperatorActionTypes.OPERATOR_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const OperatorDeleteRequest = () => {
    return async (dispatch: Dispatch<OperatorActions>) => {
        dispatch({
            type: OperatorActionTypes.OPERATOR_DELETE_REQUEST,
          });
    }
}

export const OperatorDeleteSuccess = (id: UniqueId) => {
    return async (dispatch: Dispatch<OperatorActions>) => {
        dispatch({
            type: OperatorActionTypes.OPERATOR_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const OperatorDeleteError = (message: string) => {
    return async (dispatch: Dispatch<OperatorActions>) => {
        dispatch({
            type: OperatorActionTypes.OPERATOR_DELETE_ERROR,
            payload: message,
          });
    }
}