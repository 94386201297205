/* tslint:disable */
/* eslint-disable */
/**
 * hr-report backend
 * hr-report backend
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kanya384@mail.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuthSignInRequest
 */
export interface AuthSignInRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthSignInRequest
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthSignInRequest
     */
    'pass'?: string;
}
/**
 * 
 * @export
 * @interface AuthSignInResponse
 */
export interface AuthSignInResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthSignInResponse
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthSignInResponse
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface CalcparamsCalcParamsResponse
 */
export interface CalcparamsCalcParamsResponse {
    /**
     * 
     * @type {string}
     * @memberof CalcparamsCalcParamsResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalcparamsCalcParamsResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CalcparamsCalcParamsResponse
     */
    'junior'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalcparamsCalcParamsResponse
     */
    'middle'?: number;
    /**
     * 
     * @type {string}
     * @memberof CalcparamsCalcParamsResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof CalcparamsCalcParamsResponse
     */
    'profi'?: number;
    /**
     * 
     * @type {string}
     * @memberof CalcparamsCalcParamsResponse
     */
    'start'?: string;
    /**
     * 
     * @type {number}
     * @memberof CalcparamsCalcParamsResponse
     */
    'study'?: number;
}
/**
 * 
 * @export
 * @interface CalcparamsCreateCalcParamsRequest
 */
export interface CalcparamsCreateCalcParamsRequest {
    /**
     * 
     * @type {number}
     * @memberof CalcparamsCreateCalcParamsRequest
     */
    'junior'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalcparamsCreateCalcParamsRequest
     */
    'middle'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalcparamsCreateCalcParamsRequest
     */
    'profi'?: number;
    /**
     * 
     * @type {string}
     * @memberof CalcparamsCreateCalcParamsRequest
     */
    'start'?: string;
    /**
     * 
     * @type {number}
     * @memberof CalcparamsCreateCalcParamsRequest
     */
    'study'?: number;
}
/**
 * 
 * @export
 * @interface DomainOperatorsForSearch
 */
export interface DomainOperatorsForSearch {
    /**
     * 
     * @type {string}
     * @memberof DomainOperatorsForSearch
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainOperatorsForSearch
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainOperatorsForSearch
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DomainResponsibleForSearch
 */
export interface DomainResponsibleForSearch {
    /**
     * 
     * @type {number}
     * @memberof DomainResponsibleForSearch
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomainResponsibleForSearch
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface OperatorWorkTimeCreateCorrectionRequest
 */
export interface OperatorWorkTimeCreateCorrectionRequest {
    /**
     * 
     * @type {string}
     * @memberof OperatorWorkTimeCreateCorrectionRequest
     */
    'comment'?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeCreateCorrectionRequest
     */
    'fact_worked_seconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof OperatorWorkTimeCreateCorrectionRequest
     */
    'operator_work_time_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeCreateCorrectionRequest
     */
    'post_work_time_seconds'?: number;
}
/**
 * 
 * @export
 * @interface OperatorWorkTimeCreateOperatorWorkTimeRequest
 */
export interface OperatorWorkTimeCreateOperatorWorkTimeRequest {
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeCreateOperatorWorkTimeRequest
     */
    'dop_tasks_seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeCreateOperatorWorkTimeRequest
     */
    'fact_worked_seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeCreateOperatorWorkTimeRequest
     */
    'in_call_work_time'?: number;
    /**
     * 
     * @type {string}
     * @memberof OperatorWorkTimeCreateOperatorWorkTimeRequest
     */
    'leader_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorWorkTimeCreateOperatorWorkTimeRequest
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorWorkTimeCreateOperatorWorkTimeRequest
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeCreateOperatorWorkTimeRequest
     */
    'online_seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeCreateOperatorWorkTimeRequest
     */
    'planned_break_seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeCreateOperatorWorkTimeRequest
     */
    'post_work_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeCreateOperatorWorkTimeRequest
     */
    'study_break_seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeCreateOperatorWorkTimeRequest
     */
    'tech_break_seconds'?: number;
}
/**
 * 
 * @export
 * @interface OperatorWorkTimeLeaderGroup
 */
export interface OperatorWorkTimeLeaderGroup {
    /**
     * 
     * @type {string}
     * @memberof OperatorWorkTimeLeaderGroup
     */
    'leader_name'?: string;
    /**
     * 
     * @type {Array<OperatorWorkTimeOperatorConsalidatedStat>}
     * @memberof OperatorWorkTimeLeaderGroup
     */
    'operators_tabel'?: Array<OperatorWorkTimeOperatorConsalidatedStat>;
}
/**
 * 
 * @export
 * @interface OperatorWorkTimeOperatorConsalidatedStat
 */
export interface OperatorWorkTimeOperatorConsalidatedStat {
    /**
     * 
     * @type {Array<OperatorWorkTimeOperatorsDayStat>}
     * @memberof OperatorWorkTimeOperatorConsalidatedStat
     */
    'day_stat'?: Array<OperatorWorkTimeOperatorsDayStat>;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeOperatorConsalidatedStat
     */
    'fact_worked_seconds_sum'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeOperatorConsalidatedStat
     */
    'incall_seconds_sum'?: number;
    /**
     * 
     * @type {string}
     * @memberof OperatorWorkTimeOperatorConsalidatedStat
     */
    'operator_login'?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorWorkTimeOperatorConsalidatedStat
     */
    'operator_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeOperatorConsalidatedStat
     */
    'payed_seconds_sum'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeOperatorConsalidatedStat
     */
    'post_percentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeOperatorConsalidatedStat
     */
    'post_work_time_seconds_sum'?: number;
}
/**
 * 
 * @export
 * @interface OperatorWorkTimeOperatorWorkTimeCorrectionsResponse
 */
export interface OperatorWorkTimeOperatorWorkTimeCorrectionsResponse {
    /**
     * 
     * @type {string}
     * @memberof OperatorWorkTimeOperatorWorkTimeCorrectionsResponse
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorWorkTimeOperatorWorkTimeCorrectionsResponse
     */
    'correction_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorWorkTimeOperatorWorkTimeCorrectionsResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeOperatorWorkTimeCorrectionsResponse
     */
    'fact_worked_seconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof OperatorWorkTimeOperatorWorkTimeCorrectionsResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorWorkTimeOperatorWorkTimeCorrectionsResponse
     */
    'modfied_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorWorkTimeOperatorWorkTimeCorrectionsResponse
     */
    'operator_work_time_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeOperatorWorkTimeCorrectionsResponse
     */
    'post_work_time_seconds'?: number;
}
/**
 * 
 * @export
 * @interface OperatorWorkTimeOperatorWorkTimeList
 */
export interface OperatorWorkTimeOperatorWorkTimeList {
    /**
     * 
     * @type {string}
     * @memberof OperatorWorkTimeOperatorWorkTimeList
     */
    'date'?: string;
    /**
     * 
     * @type {Array<OperatorWorkTimeCreateOperatorWorkTimeRequest>}
     * @memberof OperatorWorkTimeOperatorWorkTimeList
     */
    'list'?: Array<OperatorWorkTimeCreateOperatorWorkTimeRequest>;
}
/**
 * 
 * @export
 * @interface OperatorWorkTimeOperatorsDayStat
 */
export interface OperatorWorkTimeOperatorsDayStat {
    /**
     * 
     * @type {OperatorWorkTimeOperatorWorkTimeCorrectionsResponse}
     * @memberof OperatorWorkTimeOperatorsDayStat
     */
    'correction'?: OperatorWorkTimeOperatorWorkTimeCorrectionsResponse;
    /**
     * 
     * @type {string}
     * @memberof OperatorWorkTimeOperatorsDayStat
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeOperatorsDayStat
     */
    'fact_worked_seconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof OperatorWorkTimeOperatorsDayStat
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeOperatorsDayStat
     */
    'payed_seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeOperatorsDayStat
     */
    'post_percent'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeOperatorsDayStat
     */
    'post_seconds'?: number;
}
/**
 * 
 * @export
 * @interface OperatorWorkTimeUpdateCorrectionRequest
 */
export interface OperatorWorkTimeUpdateCorrectionRequest {
    /**
     * 
     * @type {string}
     * @memberof OperatorWorkTimeUpdateCorrectionRequest
     */
    'comment'?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeUpdateCorrectionRequest
     */
    'fact_worked_seconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof OperatorWorkTimeUpdateCorrectionRequest
     */
    'operator_work_time_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorWorkTimeUpdateCorrectionRequest
     */
    'post_work_time_seconds'?: number;
}
/**
 * 
 * @export
 * @interface PaymentCreatePaymentRequest
 */
export interface PaymentCreatePaymentRequest {
    /**
     * 
     * @type {number}
     * @memberof PaymentCreatePaymentRequest
     */
    'avans'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentCreatePaymentRequest
     */
    'bank'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentCreatePaymentRequest
     */
    'end'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentCreatePaymentRequest
     */
    'group_bonus'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentCreatePaymentRequest
     */
    'main_bonus'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentCreatePaymentRequest
     */
    'operator_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentCreatePaymentRequest
     */
    'penalty'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentCreatePaymentRequest
     */
    'processed_payments'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PaymentCreatePaymentRequest
     */
    'scoreboard_bonus'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentCreatePaymentRequest
     */
    'start'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentCreatePaymentRequest
     */
    'study_avans'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaymentCreatePaymentRequest
     */
    'worked_hours'?: number;
}
/**
 * 
 * @export
 * @interface PaymentPaymentResponse
 */
export interface PaymentPaymentResponse {
    /**
     * 
     * @type {number}
     * @memberof PaymentPaymentResponse
     */
    'avans'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPaymentResponse
     */
    'bank'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPaymentResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPaymentResponse
     */
    'end'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPaymentResponse
     */
    'group_bonus'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPaymentResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPaymentResponse
     */
    'main_bonus'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPaymentResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPaymentResponse
     */
    'operator_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPaymentResponse
     */
    'penalty'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentPaymentResponse
     */
    'processed_payments'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PaymentPaymentResponse
     */
    'scoreboard_bonus'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPaymentResponse
     */
    'start'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentPaymentResponse
     */
    'study_avans'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaymentPaymentResponse
     */
    'worked_hours'?: number;
}
/**
 * 
 * @export
 * @interface ReportDateRange
 */
export interface ReportDateRange {
    /**
     * 
     * @type {string}
     * @memberof ReportDateRange
     */
    'end'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportDateRange
     */
    'start'?: string;
}
/**
 * 
 * @export
 * @interface ReportOperator
 */
export interface ReportOperator {
    /**
     * 
     * @type {string}
     * @memberof ReportOperator
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportOperator
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportOperator
     */
    'responsible'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportOperator
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportOperator
     */
    'state_date'?: string;
}
/**
 * 
 * @export
 * @interface ReportOperatorPaymentsOfMonth
 */
export interface ReportOperatorPaymentsOfMonth {
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorPaymentsOfMonth
     */
    'avans'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorPaymentsOfMonth
     */
    'avans_study'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportOperatorPaymentsOfMonth
     */
    'bank'?: string;
    /**
     * 
     * @type {ReportDateRange}
     * @memberof ReportOperatorPaymentsOfMonth
     */
    'date_range'?: ReportDateRange;
    /**
     * ставка
     * @type {string}
     * @memberof ReportOperatorPaymentsOfMonth
     */
    'factor'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorPaymentsOfMonth
     */
    'group_bonus'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorPaymentsOfMonth
     */
    'main_bonus'?: number;
    /**
     * 
     * @type {ReportOperator}
     * @memberof ReportOperatorPaymentsOfMonth
     */
    'operator'?: ReportOperator;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorPaymentsOfMonth
     */
    'penalty'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportOperatorPaymentsOfMonth
     */
    'processed_payments'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorPaymentsOfMonth
     */
    'remaining_sum'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorPaymentsOfMonth
     */
    'salary'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorPaymentsOfMonth
     */
    'scoreboard_bonus'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorPaymentsOfMonth
     */
    'total_earned_sum'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorPaymentsOfMonth
     */
    'total_paid_sum_without_avans'?: number;
    /**
     * срок работы
     * @type {number}
     * @memberof ReportOperatorPaymentsOfMonth
     */
    'work_stage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorPaymentsOfMonth
     */
    'worked_hours'?: number;
}
/**
 * 
 * @export
 * @interface ReportReport
 */
export interface ReportReport {
    /**
     * 
     * @type {Array<ReportOperatorPaymentsOfMonth>}
     * @memberof ReportReport
     */
    'payments'?: Array<ReportOperatorPaymentsOfMonth>;
}
/**
 * 
 * @export
 * @interface RestErrorResponse
 */
export interface RestErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof RestErrorResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface RestSuccessMessage
 */
export interface RestSuccessMessage {
    /**
     * 
     * @type {string}
     * @memberof RestSuccessMessage
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UserAmoIdent
 */
export interface UserAmoIdent {
    /**
     * 
     * @type {number}
     * @memberof UserAmoIdent
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserAmoIdent
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserChangePasswordRequest
 */
export interface UserChangePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof UserChangePasswordRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface UserCreateUserRequest
 */
export interface UserCreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'created_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserCreateUserRequest
     */
    'head_operator_id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateUserRequest
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'login'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserCreateUserRequest
     */
    'mentor_is_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'pass'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'role'?: string;
}
/**
 * 
 * @export
 * @interface UserList
 */
export interface UserList {
    /**
     * 
     * @type {Array<UserAmoIdent>}
     * @memberof UserList
     */
    'head_operator'?: Array<UserAmoIdent>;
    /**
     * 
     * @type {Array<UserAmoIdent>}
     * @memberof UserList
     */
    'is_mentors'?: Array<UserAmoIdent>;
}
/**
 * 
 * @export
 * @interface UserUpdateUserRequest
 */
export interface UserUpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'created_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserUpdateUserRequest
     */
    'head_operator_id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateUserRequest
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'login'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserUpdateUserRequest
     */
    'mentor_is_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'pass'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'role'?: string;
}
/**
 * 
 * @export
 * @interface UserUserResponse
 */
export interface UserUserResponse {
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserUserResponse
     */
    'head_operator_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserUserResponse
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'login'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserUserResponse
     */
    'mentor_is_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'pass'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'role'?: string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSignInPost: async (user: AuthSignInRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiAuthSignInPost', 'user', user)
            const localVarPath = `/api/auth/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthSignInPost(user: AuthSignInRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthSignInResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthSignInPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSignInPost(user: AuthSignInRequest, options?: any): AxiosPromise<AuthSignInResponse> {
            return localVarFp.apiAuthSignInPost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * авторизация пользователя.
     * @summary авторизация пользователя.
     * @param {AuthSignInRequest} user Данные для аутентификации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthSignInPost(user: AuthSignInRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthSignInPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CalcparamsApi - axios parameter creator
 * @export
 */
export const CalcparamsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить актуальный параметры расчета.
         * @summary получить актуальный параметры расчета.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalcparamsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/calcparams/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание параметров расчета или обновлене существующего, если для from уже есть.
         * @summary создание параметров расчета или обновлене существующего, если для from уже есть.
         * @param {CalcparamsCreateCalcParamsRequest} calcparams Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalcparamsPost: async (calcparams: CalcparamsCreateCalcParamsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calcparams' is not null or undefined
            assertParamExists('apiCalcparamsPost', 'calcparams', calcparams)
            const localVarPath = `/api/calcparams/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calcparams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalcparamsApi - functional programming interface
 * @export
 */
export const CalcparamsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalcparamsApiAxiosParamCreator(configuration)
    return {
        /**
         * получить актуальный параметры расчета.
         * @summary получить актуальный параметры расчета.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCalcparamsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalcparamsCalcParamsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCalcparamsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание параметров расчета или обновлене существующего, если для from уже есть.
         * @summary создание параметров расчета или обновлене существующего, если для from уже есть.
         * @param {CalcparamsCreateCalcParamsRequest} calcparams Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCalcparamsPost(calcparams: CalcparamsCreateCalcParamsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalcparamsCalcParamsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCalcparamsPost(calcparams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalcparamsApi - factory interface
 * @export
 */
export const CalcparamsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalcparamsApiFp(configuration)
    return {
        /**
         * получить актуальный параметры расчета.
         * @summary получить актуальный параметры расчета.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalcparamsGet(options?: any): AxiosPromise<CalcparamsCalcParamsResponse> {
            return localVarFp.apiCalcparamsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * создание параметров расчета или обновлене существующего, если для from уже есть.
         * @summary создание параметров расчета или обновлене существующего, если для from уже есть.
         * @param {CalcparamsCreateCalcParamsRequest} calcparams Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalcparamsPost(calcparams: CalcparamsCreateCalcParamsRequest, options?: any): AxiosPromise<CalcparamsCalcParamsResponse> {
            return localVarFp.apiCalcparamsPost(calcparams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalcparamsApi - object-oriented interface
 * @export
 * @class CalcparamsApi
 * @extends {BaseAPI}
 */
export class CalcparamsApi extends BaseAPI {
    /**
     * получить актуальный параметры расчета.
     * @summary получить актуальный параметры расчета.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalcparamsApi
     */
    public apiCalcparamsGet(options?: AxiosRequestConfig) {
        return CalcparamsApiFp(this.configuration).apiCalcparamsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание параметров расчета или обновлене существующего, если для from уже есть.
     * @summary создание параметров расчета или обновлене существующего, если для from уже есть.
     * @param {CalcparamsCreateCalcParamsRequest} calcparams Данные пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalcparamsApi
     */
    public apiCalcparamsPost(calcparams: CalcparamsCreateCalcParamsRequest, options?: AxiosRequestConfig) {
        return CalcparamsApiFp(this.configuration).apiCalcparamsPost(calcparams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OktellApi - axios parameter creator
 * @export
 */
export const OktellApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * обрабатывает данные из Oktell.
         * @summary обрабатывает данные из Oktell.
         * @param {string} key Ключ доступа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOktellKeyPost: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiOktellKeyPost', 'key', key)
            const localVarPath = `/api/oktell/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OktellApi - functional programming interface
 * @export
 */
export const OktellApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OktellApiAxiosParamCreator(configuration)
    return {
        /**
         * обрабатывает данные из Oktell.
         * @summary обрабатывает данные из Oktell.
         * @param {string} key Ключ доступа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOktellKeyPost(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOktellKeyPost(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OktellApi - factory interface
 * @export
 */
export const OktellApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OktellApiFp(configuration)
    return {
        /**
         * обрабатывает данные из Oktell.
         * @summary обрабатывает данные из Oktell.
         * @param {string} key Ключ доступа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOktellKeyPost(key: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiOktellKeyPost(key, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OktellApi - object-oriented interface
 * @export
 * @class OktellApi
 * @extends {BaseAPI}
 */
export class OktellApi extends BaseAPI {
    /**
     * обрабатывает данные из Oktell.
     * @summary обрабатывает данные из Oktell.
     * @param {string} key Ключ доступа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OktellApi
     */
    public apiOktellKeyPost(key: string, options?: AxiosRequestConfig) {
        return OktellApiFp(this.configuration).apiOktellKeyPost(key, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OperatorWorkTimeApi - axios parameter creator
 * @export
 */
export const OperatorWorkTimeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * подтвердить корректировку.
         * @summary подтвердить корректировку.
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOperatorWorkTimeCorrectionIdApprovePatch: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOperatorWorkTimeCorrectionIdApprovePatch', 'id', id)
            const localVarPath = `/api/operator-work-time/correction/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * отклонить корректировку.
         * @summary отклонить корректировку.
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOperatorWorkTimeCorrectionIdDeclinePatch: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOperatorWorkTimeCorrectionIdDeclinePatch', 'id', id)
            const localVarPath = `/api/operator-work-time/correction/{id}/decline`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * прочитать корректировку по id.
         * @summary прочитать корректировку по id.
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOperatorWorkTimeCorrectionIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOperatorWorkTimeCorrectionIdGet', 'id', id)
            const localVarPath = `/api/operator-work-time/correction/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновить корректировку за день по оператору.
         * @summary обновить корректировку за день по оператору.
         * @param {string} id id
         * @param {OperatorWorkTimeUpdateCorrectionRequest} operatorWorkTimeCorrections Данные для создания operatorWorkTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOperatorWorkTimeCorrectionIdPatch: async (id: string, operatorWorkTimeCorrections: OperatorWorkTimeUpdateCorrectionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOperatorWorkTimeCorrectionIdPatch', 'id', id)
            // verify required parameter 'operatorWorkTimeCorrections' is not null or undefined
            assertParamExists('apiOperatorWorkTimeCorrectionIdPatch', 'operatorWorkTimeCorrections', operatorWorkTimeCorrections)
            const localVarPath = `/api/operator-work-time/correction/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operatorWorkTimeCorrections, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создать корректировку за день по оператору.
         * @summary создать корректировку за день по оператору.
         * @param {OperatorWorkTimeCreateCorrectionRequest} operatorWorkTimeCorrections Данные для создания operatorWorkTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOperatorWorkTimeCorrectionPost: async (operatorWorkTimeCorrections: OperatorWorkTimeCreateCorrectionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'operatorWorkTimeCorrections' is not null or undefined
            assertParamExists('apiOperatorWorkTimeCorrectionPost', 'operatorWorkTimeCorrections', operatorWorkTimeCorrections)
            const localVarPath = `/api/operator-work-time/correction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operatorWorkTimeCorrections, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание operatorWorkTime за день.
         * @summary создание operatorWorkTime за день.
         * @param {OperatorWorkTimeOperatorWorkTimeList} operatorWorkTimeCorrections Данные для создания operatorWorkTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOperatorWorkTimePost: async (operatorWorkTimeCorrections: OperatorWorkTimeOperatorWorkTimeList, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'operatorWorkTimeCorrections' is not null or undefined
            assertParamExists('apiOperatorWorkTimePost', 'operatorWorkTimeCorrections', operatorWorkTimeCorrections)
            const localVarPath = `/api/operator-work-time/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operatorWorkTimeCorrections, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * прочитать табель за месяц.
         * @summary прочитать табель за месяц.
         * @param {number} month месяц
         * @param {number} year год
         * @param {Array<string>} [rgs] имена руководителей групп
         * @param {Array<string>} [operatorLogins] логины
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOperatorWorkTimeTabelMonthYearGet: async (month: number, year: number, rgs?: Array<string>, operatorLogins?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('apiOperatorWorkTimeTabelMonthYearGet', 'month', month)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('apiOperatorWorkTimeTabelMonthYearGet', 'year', year)
            const localVarPath = `/api/operator-work-time/tabel/{month}/{year}`
                .replace(`{${"month"}}`, encodeURIComponent(String(month)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (rgs) {
                localVarQueryParameter['rgs[]'] = rgs.join(COLLECTION_FORMATS.csv);
            }

            if (operatorLogins) {
                localVarQueryParameter['operator_logins[]'] = operatorLogins.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * скачать табель в xlsx.
         * @summary скачать табель в xlsx.
         * @param {number} month месяц
         * @param {number} year год
         * @param {Array<string>} [rgs] имена руководителей групп
         * @param {Array<string>} [operatorLogins] логины
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOperatorWorkTimeTabelMonthYearXlsxGet: async (month: number, year: number, rgs?: Array<string>, operatorLogins?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('apiOperatorWorkTimeTabelMonthYearXlsxGet', 'month', month)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('apiOperatorWorkTimeTabelMonthYearXlsxGet', 'year', year)
            const localVarPath = `/api/operator-work-time/tabel/{month}/{year}/xlsx`
                .replace(`{${"month"}}`, encodeURIComponent(String(month)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (rgs) {
                localVarQueryParameter['rgs[]'] = rgs.join(COLLECTION_FORMATS.csv);
            }

            if (operatorLogins) {
                localVarQueryParameter['operator_logins[]'] = operatorLogins.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OperatorWorkTimeApi - functional programming interface
 * @export
 */
export const OperatorWorkTimeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OperatorWorkTimeApiAxiosParamCreator(configuration)
    return {
        /**
         * подтвердить корректировку.
         * @summary подтвердить корректировку.
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOperatorWorkTimeCorrectionIdApprovePatch(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorWorkTimeLeaderGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOperatorWorkTimeCorrectionIdApprovePatch(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * отклонить корректировку.
         * @summary отклонить корректировку.
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOperatorWorkTimeCorrectionIdDeclinePatch(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorWorkTimeLeaderGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOperatorWorkTimeCorrectionIdDeclinePatch(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * прочитать корректировку по id.
         * @summary прочитать корректировку по id.
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOperatorWorkTimeCorrectionIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OperatorWorkTimeOperatorWorkTimeCorrectionsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOperatorWorkTimeCorrectionIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновить корректировку за день по оператору.
         * @summary обновить корректировку за день по оператору.
         * @param {string} id id
         * @param {OperatorWorkTimeUpdateCorrectionRequest} operatorWorkTimeCorrections Данные для создания operatorWorkTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOperatorWorkTimeCorrectionIdPatch(id: string, operatorWorkTimeCorrections: OperatorWorkTimeUpdateCorrectionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorWorkTimeLeaderGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOperatorWorkTimeCorrectionIdPatch(id, operatorWorkTimeCorrections, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создать корректировку за день по оператору.
         * @summary создать корректировку за день по оператору.
         * @param {OperatorWorkTimeCreateCorrectionRequest} operatorWorkTimeCorrections Данные для создания operatorWorkTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOperatorWorkTimeCorrectionPost(operatorWorkTimeCorrections: OperatorWorkTimeCreateCorrectionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorWorkTimeLeaderGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOperatorWorkTimeCorrectionPost(operatorWorkTimeCorrections, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание operatorWorkTime за день.
         * @summary создание operatorWorkTime за день.
         * @param {OperatorWorkTimeOperatorWorkTimeList} operatorWorkTimeCorrections Данные для создания operatorWorkTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOperatorWorkTimePost(operatorWorkTimeCorrections: OperatorWorkTimeOperatorWorkTimeList, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOperatorWorkTimePost(operatorWorkTimeCorrections, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * прочитать табель за месяц.
         * @summary прочитать табель за месяц.
         * @param {number} month месяц
         * @param {number} year год
         * @param {Array<string>} [rgs] имена руководителей групп
         * @param {Array<string>} [operatorLogins] логины
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOperatorWorkTimeTabelMonthYearGet(month: number, year: number, rgs?: Array<string>, operatorLogins?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OperatorWorkTimeLeaderGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOperatorWorkTimeTabelMonthYearGet(month, year, rgs, operatorLogins, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * скачать табель в xlsx.
         * @summary скачать табель в xlsx.
         * @param {number} month месяц
         * @param {number} year год
         * @param {Array<string>} [rgs] имена руководителей групп
         * @param {Array<string>} [operatorLogins] логины
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOperatorWorkTimeTabelMonthYearXlsxGet(month: number, year: number, rgs?: Array<string>, operatorLogins?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOperatorWorkTimeTabelMonthYearXlsxGet(month, year, rgs, operatorLogins, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OperatorWorkTimeApi - factory interface
 * @export
 */
export const OperatorWorkTimeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OperatorWorkTimeApiFp(configuration)
    return {
        /**
         * подтвердить корректировку.
         * @summary подтвердить корректировку.
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOperatorWorkTimeCorrectionIdApprovePatch(id: string, options?: any): AxiosPromise<OperatorWorkTimeLeaderGroup> {
            return localVarFp.apiOperatorWorkTimeCorrectionIdApprovePatch(id, options).then((request) => request(axios, basePath));
        },
        /**
         * отклонить корректировку.
         * @summary отклонить корректировку.
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOperatorWorkTimeCorrectionIdDeclinePatch(id: string, options?: any): AxiosPromise<OperatorWorkTimeLeaderGroup> {
            return localVarFp.apiOperatorWorkTimeCorrectionIdDeclinePatch(id, options).then((request) => request(axios, basePath));
        },
        /**
         * прочитать корректировку по id.
         * @summary прочитать корректировку по id.
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOperatorWorkTimeCorrectionIdGet(id: string, options?: any): AxiosPromise<Array<OperatorWorkTimeOperatorWorkTimeCorrectionsResponse>> {
            return localVarFp.apiOperatorWorkTimeCorrectionIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * обновить корректировку за день по оператору.
         * @summary обновить корректировку за день по оператору.
         * @param {string} id id
         * @param {OperatorWorkTimeUpdateCorrectionRequest} operatorWorkTimeCorrections Данные для создания operatorWorkTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOperatorWorkTimeCorrectionIdPatch(id: string, operatorWorkTimeCorrections: OperatorWorkTimeUpdateCorrectionRequest, options?: any): AxiosPromise<OperatorWorkTimeLeaderGroup> {
            return localVarFp.apiOperatorWorkTimeCorrectionIdPatch(id, operatorWorkTimeCorrections, options).then((request) => request(axios, basePath));
        },
        /**
         * создать корректировку за день по оператору.
         * @summary создать корректировку за день по оператору.
         * @param {OperatorWorkTimeCreateCorrectionRequest} operatorWorkTimeCorrections Данные для создания operatorWorkTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOperatorWorkTimeCorrectionPost(operatorWorkTimeCorrections: OperatorWorkTimeCreateCorrectionRequest, options?: any): AxiosPromise<OperatorWorkTimeLeaderGroup> {
            return localVarFp.apiOperatorWorkTimeCorrectionPost(operatorWorkTimeCorrections, options).then((request) => request(axios, basePath));
        },
        /**
         * создание operatorWorkTime за день.
         * @summary создание operatorWorkTime за день.
         * @param {OperatorWorkTimeOperatorWorkTimeList} operatorWorkTimeCorrections Данные для создания operatorWorkTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOperatorWorkTimePost(operatorWorkTimeCorrections: OperatorWorkTimeOperatorWorkTimeList, options?: any): AxiosPromise<void> {
            return localVarFp.apiOperatorWorkTimePost(operatorWorkTimeCorrections, options).then((request) => request(axios, basePath));
        },
        /**
         * прочитать табель за месяц.
         * @summary прочитать табель за месяц.
         * @param {number} month месяц
         * @param {number} year год
         * @param {Array<string>} [rgs] имена руководителей групп
         * @param {Array<string>} [operatorLogins] логины
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOperatorWorkTimeTabelMonthYearGet(month: number, year: number, rgs?: Array<string>, operatorLogins?: Array<string>, options?: any): AxiosPromise<Array<OperatorWorkTimeLeaderGroup>> {
            return localVarFp.apiOperatorWorkTimeTabelMonthYearGet(month, year, rgs, operatorLogins, options).then((request) => request(axios, basePath));
        },
        /**
         * скачать табель в xlsx.
         * @summary скачать табель в xlsx.
         * @param {number} month месяц
         * @param {number} year год
         * @param {Array<string>} [rgs] имена руководителей групп
         * @param {Array<string>} [operatorLogins] логины
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOperatorWorkTimeTabelMonthYearXlsxGet(month: number, year: number, rgs?: Array<string>, operatorLogins?: Array<string>, options?: any): AxiosPromise<string> {
            return localVarFp.apiOperatorWorkTimeTabelMonthYearXlsxGet(month, year, rgs, operatorLogins, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OperatorWorkTimeApi - object-oriented interface
 * @export
 * @class OperatorWorkTimeApi
 * @extends {BaseAPI}
 */
export class OperatorWorkTimeApi extends BaseAPI {
    /**
     * подтвердить корректировку.
     * @summary подтвердить корректировку.
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorWorkTimeApi
     */
    public apiOperatorWorkTimeCorrectionIdApprovePatch(id: string, options?: AxiosRequestConfig) {
        return OperatorWorkTimeApiFp(this.configuration).apiOperatorWorkTimeCorrectionIdApprovePatch(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * отклонить корректировку.
     * @summary отклонить корректировку.
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorWorkTimeApi
     */
    public apiOperatorWorkTimeCorrectionIdDeclinePatch(id: string, options?: AxiosRequestConfig) {
        return OperatorWorkTimeApiFp(this.configuration).apiOperatorWorkTimeCorrectionIdDeclinePatch(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * прочитать корректировку по id.
     * @summary прочитать корректировку по id.
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorWorkTimeApi
     */
    public apiOperatorWorkTimeCorrectionIdGet(id: string, options?: AxiosRequestConfig) {
        return OperatorWorkTimeApiFp(this.configuration).apiOperatorWorkTimeCorrectionIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновить корректировку за день по оператору.
     * @summary обновить корректировку за день по оператору.
     * @param {string} id id
     * @param {OperatorWorkTimeUpdateCorrectionRequest} operatorWorkTimeCorrections Данные для создания operatorWorkTime
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorWorkTimeApi
     */
    public apiOperatorWorkTimeCorrectionIdPatch(id: string, operatorWorkTimeCorrections: OperatorWorkTimeUpdateCorrectionRequest, options?: AxiosRequestConfig) {
        return OperatorWorkTimeApiFp(this.configuration).apiOperatorWorkTimeCorrectionIdPatch(id, operatorWorkTimeCorrections, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создать корректировку за день по оператору.
     * @summary создать корректировку за день по оператору.
     * @param {OperatorWorkTimeCreateCorrectionRequest} operatorWorkTimeCorrections Данные для создания operatorWorkTime
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorWorkTimeApi
     */
    public apiOperatorWorkTimeCorrectionPost(operatorWorkTimeCorrections: OperatorWorkTimeCreateCorrectionRequest, options?: AxiosRequestConfig) {
        return OperatorWorkTimeApiFp(this.configuration).apiOperatorWorkTimeCorrectionPost(operatorWorkTimeCorrections, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание operatorWorkTime за день.
     * @summary создание operatorWorkTime за день.
     * @param {OperatorWorkTimeOperatorWorkTimeList} operatorWorkTimeCorrections Данные для создания operatorWorkTime
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorWorkTimeApi
     */
    public apiOperatorWorkTimePost(operatorWorkTimeCorrections: OperatorWorkTimeOperatorWorkTimeList, options?: AxiosRequestConfig) {
        return OperatorWorkTimeApiFp(this.configuration).apiOperatorWorkTimePost(operatorWorkTimeCorrections, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * прочитать табель за месяц.
     * @summary прочитать табель за месяц.
     * @param {number} month месяц
     * @param {number} year год
     * @param {Array<string>} [rgs] имена руководителей групп
     * @param {Array<string>} [operatorLogins] логины
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorWorkTimeApi
     */
    public apiOperatorWorkTimeTabelMonthYearGet(month: number, year: number, rgs?: Array<string>, operatorLogins?: Array<string>, options?: AxiosRequestConfig) {
        return OperatorWorkTimeApiFp(this.configuration).apiOperatorWorkTimeTabelMonthYearGet(month, year, rgs, operatorLogins, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * скачать табель в xlsx.
     * @summary скачать табель в xlsx.
     * @param {number} month месяц
     * @param {number} year год
     * @param {Array<string>} [rgs] имена руководителей групп
     * @param {Array<string>} [operatorLogins] логины
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorWorkTimeApi
     */
    public apiOperatorWorkTimeTabelMonthYearXlsxGet(month: number, year: number, rgs?: Array<string>, operatorLogins?: Array<string>, options?: AxiosRequestConfig) {
        return OperatorWorkTimeApiFp(this.configuration).apiOperatorWorkTimeTabelMonthYearXlsxGet(month, year, rgs, operatorLogins, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * загрузить бонусы из xlsx.
         * @summary загрузить бонусы из xlsx.
         * @param {File} [file] File to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentBonusesPost: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/bonuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить актуальный параметры расчета.
         * @summary получить актуальный параметры расчета.
         * @param {string} operatorId Идентификатор пользователя
         * @param {string} start дата от
         * @param {string} end дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentOperatorIdStartEndGet: async (operatorId: string, start: string, end: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'operatorId' is not null or undefined
            assertParamExists('apiPaymentOperatorIdStartEndGet', 'operatorId', operatorId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('apiPaymentOperatorIdStartEndGet', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('apiPaymentOperatorIdStartEndGet', 'end', end)
            const localVarPath = `/api/payment/{operatorId}/{start}/{end}`
                .replace(`{${"operatorId"}}`, encodeURIComponent(String(operatorId)))
                .replace(`{${"start"}}`, encodeURIComponent(String(start)))
                .replace(`{${"end"}}`, encodeURIComponent(String(end)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить актуальный параметры расчета.
         * @summary получить актуальный параметры расчета.
         * @param {string} operatorId Идентификатор пользователя
         * @param {string} start дата от
         * @param {string} end дата до
         * @param {string} paymentType вид платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentOperatorIdStartEndPaymentTypePut: async (operatorId: string, start: string, end: string, paymentType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'operatorId' is not null or undefined
            assertParamExists('apiPaymentOperatorIdStartEndPaymentTypePut', 'operatorId', operatorId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('apiPaymentOperatorIdStartEndPaymentTypePut', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('apiPaymentOperatorIdStartEndPaymentTypePut', 'end', end)
            // verify required parameter 'paymentType' is not null or undefined
            assertParamExists('apiPaymentOperatorIdStartEndPaymentTypePut', 'paymentType', paymentType)
            const localVarPath = `/api/payment/{operatorId}/{start}/{end}/{payment_type}`
                .replace(`{${"operatorId"}}`, encodeURIComponent(String(operatorId)))
                .replace(`{${"start"}}`, encodeURIComponent(String(start)))
                .replace(`{${"end"}}`, encodeURIComponent(String(end)))
                .replace(`{${"payment_type"}}`, encodeURIComponent(String(paymentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание платежей по оператору в месяце.
         * @summary создание платежей по оператору в месяце.
         * @param {PaymentCreatePaymentRequest} payment Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentPost: async (payment: PaymentCreatePaymentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'payment' is not null or undefined
            assertParamExists('apiPaymentPost', 'payment', payment)
            const localVarPath = `/api/payment/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * загрузить бонусы из xlsx.
         * @summary загрузить бонусы из xlsx.
         * @param {File} [file] File to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentBonusesPost(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentBonusesPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить актуальный параметры расчета.
         * @summary получить актуальный параметры расчета.
         * @param {string} operatorId Идентификатор пользователя
         * @param {string} start дата от
         * @param {string} end дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentOperatorIdStartEndGet(operatorId: string, start: string, end: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentPaymentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentOperatorIdStartEndGet(operatorId, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить актуальный параметры расчета.
         * @summary получить актуальный параметры расчета.
         * @param {string} operatorId Идентификатор пользователя
         * @param {string} start дата от
         * @param {string} end дата до
         * @param {string} paymentType вид платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentOperatorIdStartEndPaymentTypePut(operatorId: string, start: string, end: string, paymentType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportOperatorPaymentsOfMonth>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentOperatorIdStartEndPaymentTypePut(operatorId, start, end, paymentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание платежей по оператору в месяце.
         * @summary создание платежей по оператору в месяце.
         * @param {PaymentCreatePaymentRequest} payment Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentPost(payment: PaymentCreatePaymentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportOperatorPaymentsOfMonth>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentPost(payment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentApiFp(configuration)
    return {
        /**
         * загрузить бонусы из xlsx.
         * @summary загрузить бонусы из xlsx.
         * @param {File} [file] File to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentBonusesPost(file?: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiPaymentBonusesPost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * получить актуальный параметры расчета.
         * @summary получить актуальный параметры расчета.
         * @param {string} operatorId Идентификатор пользователя
         * @param {string} start дата от
         * @param {string} end дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentOperatorIdStartEndGet(operatorId: string, start: string, end: string, options?: any): AxiosPromise<PaymentPaymentResponse> {
            return localVarFp.apiPaymentOperatorIdStartEndGet(operatorId, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * получить актуальный параметры расчета.
         * @summary получить актуальный параметры расчета.
         * @param {string} operatorId Идентификатор пользователя
         * @param {string} start дата от
         * @param {string} end дата до
         * @param {string} paymentType вид платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentOperatorIdStartEndPaymentTypePut(operatorId: string, start: string, end: string, paymentType: string, options?: any): AxiosPromise<ReportOperatorPaymentsOfMonth> {
            return localVarFp.apiPaymentOperatorIdStartEndPaymentTypePut(operatorId, start, end, paymentType, options).then((request) => request(axios, basePath));
        },
        /**
         * создание платежей по оператору в месяце.
         * @summary создание платежей по оператору в месяце.
         * @param {PaymentCreatePaymentRequest} payment Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentPost(payment: PaymentCreatePaymentRequest, options?: any): AxiosPromise<ReportOperatorPaymentsOfMonth> {
            return localVarFp.apiPaymentPost(payment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * загрузить бонусы из xlsx.
     * @summary загрузить бонусы из xlsx.
     * @param {File} [file] File to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public apiPaymentBonusesPost(file?: File, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).apiPaymentBonusesPost(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить актуальный параметры расчета.
     * @summary получить актуальный параметры расчета.
     * @param {string} operatorId Идентификатор пользователя
     * @param {string} start дата от
     * @param {string} end дата до
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public apiPaymentOperatorIdStartEndGet(operatorId: string, start: string, end: string, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).apiPaymentOperatorIdStartEndGet(operatorId, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить актуальный параметры расчета.
     * @summary получить актуальный параметры расчета.
     * @param {string} operatorId Идентификатор пользователя
     * @param {string} start дата от
     * @param {string} end дата до
     * @param {string} paymentType вид платежа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public apiPaymentOperatorIdStartEndPaymentTypePut(operatorId: string, start: string, end: string, paymentType: string, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).apiPaymentOperatorIdStartEndPaymentTypePut(operatorId, start, end, paymentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание платежей по оператору в месяце.
     * @summary создание платежей по оператору в месяце.
     * @param {PaymentCreatePaymentRequest} payment Данные пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public apiPaymentPost(payment: PaymentCreatePaymentRequest, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).apiPaymentPost(payment, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * прочитать список leaderName для поиска.
         * @summary прочитать список leaderName для поиска.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportLeadernamesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/leadernames`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * прочитать список операторов для поиска.
         * @summary прочитать список операторов для поиска.
         * @param {string} start дата от
         * @param {string} end дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportOperatorsStartEndGet: async (start: string, end: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('apiReportOperatorsStartEndGet', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('apiReportOperatorsStartEndGet', 'end', end)
            const localVarPath = `/api/report/operators/{start}/{end}`
                .replace(`{${"start"}}`, encodeURIComponent(String(start)))
                .replace(`{${"end"}}`, encodeURIComponent(String(end)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * выгрузка в тинькофф.
         * @summary выгрузка в тинькофф.
         * @param {string} start дата от
         * @param {string} end дата до
         * @param {string} [operatorId] Идентификатор оператора
         * @param {string} [operatorRole] роль оператора
         * @param {string} [bank] банк
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportOperatorsStartEndSberPost: async (start: string, end: string, operatorId?: string, operatorRole?: string, bank?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('apiReportOperatorsStartEndSberPost', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('apiReportOperatorsStartEndSberPost', 'end', end)
            const localVarPath = `/api/report/operators/{start}/{end}/sber`
                .replace(`{${"start"}}`, encodeURIComponent(String(start)))
                .replace(`{${"end"}}`, encodeURIComponent(String(end)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (operatorId !== undefined) {
                localVarQueryParameter['operatorId'] = operatorId;
            }

            if (operatorRole !== undefined) {
                localVarQueryParameter['operatorRole'] = operatorRole;
            }

            if (bank !== undefined) {
                localVarQueryParameter['bank'] = bank;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * выгрузка в тинькофф.
         * @summary выгрузка в тинькофф.
         * @param {string} start дата от
         * @param {string} end дата до
         * @param {string} [operatorId] Идентификатор оператора
         * @param {string} [operatorRole] роль оператора
         * @param {string} [bank] банк
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportOperatorsStartEndTinkoffPost: async (start: string, end: string, operatorId?: string, operatorRole?: string, bank?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('apiReportOperatorsStartEndTinkoffPost', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('apiReportOperatorsStartEndTinkoffPost', 'end', end)
            const localVarPath = `/api/report/operators/{start}/{end}/tinkoff`
                .replace(`{${"start"}}`, encodeURIComponent(String(start)))
                .replace(`{${"end"}}`, encodeURIComponent(String(end)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (operatorId !== undefined) {
                localVarQueryParameter['operatorId'] = operatorId;
            }

            if (operatorRole !== undefined) {
                localVarQueryParameter['operatorRole'] = operatorRole;
            }

            if (bank !== undefined) {
                localVarQueryParameter['bank'] = bank;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * уволить интерна по amo id.
         * @summary уволить интерна по amo id.
         * @param {string} start дата от
         * @param {string} end дата до
         * @param {string} [operatorId] Идентификатор оператора
         * @param {string} [operatorRole] роль оператора
         * @param {string} [bank] банк
         * @param {number} [responsible] ответственный
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportOperatorsStartEndXlsxPost: async (start: string, end: string, operatorId?: string, operatorRole?: string, bank?: string, responsible?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('apiReportOperatorsStartEndXlsxPost', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('apiReportOperatorsStartEndXlsxPost', 'end', end)
            const localVarPath = `/api/report/operators/{start}/{end}/xlsx`
                .replace(`{${"start"}}`, encodeURIComponent(String(start)))
                .replace(`{${"end"}}`, encodeURIComponent(String(end)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (operatorId !== undefined) {
                localVarQueryParameter['operatorId'] = operatorId;
            }

            if (operatorRole !== undefined) {
                localVarQueryParameter['operatorRole'] = operatorRole;
            }

            if (bank !== undefined) {
                localVarQueryParameter['bank'] = bank;
            }

            if (responsible !== undefined) {
                localVarQueryParameter['responsible'] = responsible;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * прочитать список операторов для поиска.
         * @summary прочитать список операторов для поиска.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportResponsibleGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/responsible`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * прочитать таблицу начислений.
         * @summary прочитать таблицу начислений.
         * @param {string} start дата от
         * @param {string} end дата до
         * @param {string} [operatorId] Идентификатор оператора
         * @param {string} [operatorRole] роль оператора
         * @param {string} [bank] банк оператора
         * @param {number} [responsible] ответственный
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportStartEndGet: async (start: string, end: string, operatorId?: string, operatorRole?: string, bank?: string, responsible?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('apiReportStartEndGet', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('apiReportStartEndGet', 'end', end)
            const localVarPath = `/api/report/{start}/{end}`
                .replace(`{${"start"}}`, encodeURIComponent(String(start)))
                .replace(`{${"end"}}`, encodeURIComponent(String(end)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (operatorId !== undefined) {
                localVarQueryParameter['operatorId'] = operatorId;
            }

            if (operatorRole !== undefined) {
                localVarQueryParameter['operatorRole'] = operatorRole;
            }

            if (bank !== undefined) {
                localVarQueryParameter['bank'] = bank;
            }

            if (responsible !== undefined) {
                localVarQueryParameter['responsible'] = responsible;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * принудительное обновление операторов.
         * @summary принудительное обновление операторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportUpdateOperatorsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/update-operators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
    return {
        /**
         * прочитать список leaderName для поиска.
         * @summary прочитать список leaderName для поиска.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportLeadernamesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportLeadernamesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * прочитать список операторов для поиска.
         * @summary прочитать список операторов для поиска.
         * @param {string} start дата от
         * @param {string} end дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportOperatorsStartEndGet(start: string, end: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DomainOperatorsForSearch>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportOperatorsStartEndGet(start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * выгрузка в тинькофф.
         * @summary выгрузка в тинькофф.
         * @param {string} start дата от
         * @param {string} end дата до
         * @param {string} [operatorId] Идентификатор оператора
         * @param {string} [operatorRole] роль оператора
         * @param {string} [bank] банк
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportOperatorsStartEndSberPost(start: string, end: string, operatorId?: string, operatorRole?: string, bank?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportOperatorsStartEndSberPost(start, end, operatorId, operatorRole, bank, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * выгрузка в тинькофф.
         * @summary выгрузка в тинькофф.
         * @param {string} start дата от
         * @param {string} end дата до
         * @param {string} [operatorId] Идентификатор оператора
         * @param {string} [operatorRole] роль оператора
         * @param {string} [bank] банк
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportOperatorsStartEndTinkoffPost(start: string, end: string, operatorId?: string, operatorRole?: string, bank?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportOperatorsStartEndTinkoffPost(start, end, operatorId, operatorRole, bank, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * уволить интерна по amo id.
         * @summary уволить интерна по amo id.
         * @param {string} start дата от
         * @param {string} end дата до
         * @param {string} [operatorId] Идентификатор оператора
         * @param {string} [operatorRole] роль оператора
         * @param {string} [bank] банк
         * @param {number} [responsible] ответственный
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportOperatorsStartEndXlsxPost(start: string, end: string, operatorId?: string, operatorRole?: string, bank?: string, responsible?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportOperatorsStartEndXlsxPost(start, end, operatorId, operatorRole, bank, responsible, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * прочитать список операторов для поиска.
         * @summary прочитать список операторов для поиска.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportResponsibleGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DomainResponsibleForSearch>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportResponsibleGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * прочитать таблицу начислений.
         * @summary прочитать таблицу начислений.
         * @param {string} start дата от
         * @param {string} end дата до
         * @param {string} [operatorId] Идентификатор оператора
         * @param {string} [operatorRole] роль оператора
         * @param {string} [bank] банк оператора
         * @param {number} [responsible] ответственный
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportStartEndGet(start: string, end: string, operatorId?: string, operatorRole?: string, bank?: string, responsible?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportStartEndGet(start, end, operatorId, operatorRole, bank, responsible, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * принудительное обновление операторов.
         * @summary принудительное обновление операторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportUpdateOperatorsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportUpdateOperatorsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportApiFp(configuration)
    return {
        /**
         * прочитать список leaderName для поиска.
         * @summary прочитать список leaderName для поиска.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportLeadernamesGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiReportLeadernamesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * прочитать список операторов для поиска.
         * @summary прочитать список операторов для поиска.
         * @param {string} start дата от
         * @param {string} end дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportOperatorsStartEndGet(start: string, end: string, options?: any): AxiosPromise<Array<DomainOperatorsForSearch>> {
            return localVarFp.apiReportOperatorsStartEndGet(start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * выгрузка в тинькофф.
         * @summary выгрузка в тинькофф.
         * @param {string} start дата от
         * @param {string} end дата до
         * @param {string} [operatorId] Идентификатор оператора
         * @param {string} [operatorRole] роль оператора
         * @param {string} [bank] банк
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportOperatorsStartEndSberPost(start: string, end: string, operatorId?: string, operatorRole?: string, bank?: string, options?: any): AxiosPromise<File> {
            return localVarFp.apiReportOperatorsStartEndSberPost(start, end, operatorId, operatorRole, bank, options).then((request) => request(axios, basePath));
        },
        /**
         * выгрузка в тинькофф.
         * @summary выгрузка в тинькофф.
         * @param {string} start дата от
         * @param {string} end дата до
         * @param {string} [operatorId] Идентификатор оператора
         * @param {string} [operatorRole] роль оператора
         * @param {string} [bank] банк
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportOperatorsStartEndTinkoffPost(start: string, end: string, operatorId?: string, operatorRole?: string, bank?: string, options?: any): AxiosPromise<File> {
            return localVarFp.apiReportOperatorsStartEndTinkoffPost(start, end, operatorId, operatorRole, bank, options).then((request) => request(axios, basePath));
        },
        /**
         * уволить интерна по amo id.
         * @summary уволить интерна по amo id.
         * @param {string} start дата от
         * @param {string} end дата до
         * @param {string} [operatorId] Идентификатор оператора
         * @param {string} [operatorRole] роль оператора
         * @param {string} [bank] банк
         * @param {number} [responsible] ответственный
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportOperatorsStartEndXlsxPost(start: string, end: string, operatorId?: string, operatorRole?: string, bank?: string, responsible?: number, options?: any): AxiosPromise<File> {
            return localVarFp.apiReportOperatorsStartEndXlsxPost(start, end, operatorId, operatorRole, bank, responsible, options).then((request) => request(axios, basePath));
        },
        /**
         * прочитать список операторов для поиска.
         * @summary прочитать список операторов для поиска.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportResponsibleGet(options?: any): AxiosPromise<Array<DomainResponsibleForSearch>> {
            return localVarFp.apiReportResponsibleGet(options).then((request) => request(axios, basePath));
        },
        /**
         * прочитать таблицу начислений.
         * @summary прочитать таблицу начислений.
         * @param {string} start дата от
         * @param {string} end дата до
         * @param {string} [operatorId] Идентификатор оператора
         * @param {string} [operatorRole] роль оператора
         * @param {string} [bank] банк оператора
         * @param {number} [responsible] ответственный
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportStartEndGet(start: string, end: string, operatorId?: string, operatorRole?: string, bank?: string, responsible?: number, options?: any): AxiosPromise<ReportReport> {
            return localVarFp.apiReportStartEndGet(start, end, operatorId, operatorRole, bank, responsible, options).then((request) => request(axios, basePath));
        },
        /**
         * принудительное обновление операторов.
         * @summary принудительное обновление операторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportUpdateOperatorsPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiReportUpdateOperatorsPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * прочитать список leaderName для поиска.
     * @summary прочитать список leaderName для поиска.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportLeadernamesGet(options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiReportLeadernamesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * прочитать список операторов для поиска.
     * @summary прочитать список операторов для поиска.
     * @param {string} start дата от
     * @param {string} end дата до
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportOperatorsStartEndGet(start: string, end: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiReportOperatorsStartEndGet(start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * выгрузка в тинькофф.
     * @summary выгрузка в тинькофф.
     * @param {string} start дата от
     * @param {string} end дата до
     * @param {string} [operatorId] Идентификатор оператора
     * @param {string} [operatorRole] роль оператора
     * @param {string} [bank] банк
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportOperatorsStartEndSberPost(start: string, end: string, operatorId?: string, operatorRole?: string, bank?: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiReportOperatorsStartEndSberPost(start, end, operatorId, operatorRole, bank, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * выгрузка в тинькофф.
     * @summary выгрузка в тинькофф.
     * @param {string} start дата от
     * @param {string} end дата до
     * @param {string} [operatorId] Идентификатор оператора
     * @param {string} [operatorRole] роль оператора
     * @param {string} [bank] банк
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportOperatorsStartEndTinkoffPost(start: string, end: string, operatorId?: string, operatorRole?: string, bank?: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiReportOperatorsStartEndTinkoffPost(start, end, operatorId, operatorRole, bank, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * уволить интерна по amo id.
     * @summary уволить интерна по amo id.
     * @param {string} start дата от
     * @param {string} end дата до
     * @param {string} [operatorId] Идентификатор оператора
     * @param {string} [operatorRole] роль оператора
     * @param {string} [bank] банк
     * @param {number} [responsible] ответственный
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportOperatorsStartEndXlsxPost(start: string, end: string, operatorId?: string, operatorRole?: string, bank?: string, responsible?: number, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiReportOperatorsStartEndXlsxPost(start, end, operatorId, operatorRole, bank, responsible, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * прочитать список операторов для поиска.
     * @summary прочитать список операторов для поиска.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportResponsibleGet(options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiReportResponsibleGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * прочитать таблицу начислений.
     * @summary прочитать таблицу начислений.
     * @param {string} start дата от
     * @param {string} end дата до
     * @param {string} [operatorId] Идентификатор оператора
     * @param {string} [operatorRole] роль оператора
     * @param {string} [bank] банк оператора
     * @param {number} [responsible] ответственный
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportStartEndGet(start: string, end: string, operatorId?: string, operatorRole?: string, bank?: string, responsible?: number, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiReportStartEndGet(start, end, operatorId, operatorRole, bank, responsible, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * принудительное обновление операторов.
     * @summary принудительное обновление операторов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportUpdateOperatorsPost(options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiReportUpdateOperatorsPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список всех старших операторов и менторов.
         * @summary получить список всех старших операторов и менторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDictionaryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/dictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список всех источников.
         * @summary получить список всех пользователей.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить пользователя
         * @summary удалить пользователя
         * @param {string} id Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdDelete', 'id', id)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить платеж по id.
         * @summary получить платеж по id.
         * @param {string} id Идентификатор
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdGet', 'id', id)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить платеж по id.
         * @summary получить платеж по id.
         * @param {string} id Идентификатор
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdNameGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdNameGet', 'id', id)
            const localVarPath = `/api/user/{id}/name`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * смена пароля пользователя.
         * @summary смена пароля пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserChangePasswordRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdPasswordPut: async (id: string, user: UserChangePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdPasswordPut', 'id', id)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiUserIdPasswordPut', 'user', user)
            const localVarPath = `/api/user/{id}/password`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление пользователя.
         * @summary обновление пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserUpdateUserRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdPut: async (id: string, user: UserUpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdPut', 'id', id)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiUserIdPut', 'user', user)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание пользователя.
         * @summary создание пользователя.
         * @param {UserCreateUserRequest} user Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost: async (user: UserCreateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiUserPost', 'user', user)
            const localVarPath = `/api/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список всех старших операторов и менторов.
         * @summary получить список всех старших операторов и менторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserDictionaryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserDictionaryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список всех источников.
         * @summary получить список всех пользователей.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserUserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить пользователя
         * @summary удалить пользователя
         * @param {string} id Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить платеж по id.
         * @summary получить платеж по id.
         * @param {string} id Идентификатор
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить платеж по id.
         * @summary получить платеж по id.
         * @param {string} id Идентификатор
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdNameGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestSuccessMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdNameGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * смена пароля пользователя.
         * @summary смена пароля пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserChangePasswordRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdPasswordPut(id: string, user: UserChangePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdPasswordPut(id, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление пользователя.
         * @summary обновление пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserUpdateUserRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdPut(id: string, user: UserUpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdPut(id, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание пользователя.
         * @summary создание пользователя.
         * @param {UserCreateUserRequest} user Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserPost(user: UserCreateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * получить список всех старших операторов и менторов.
         * @summary получить список всех старших операторов и менторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDictionaryGet(options?: any): AxiosPromise<UserList> {
            return localVarFp.apiUserDictionaryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * получить список всех источников.
         * @summary получить список всех пользователей.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet(options?: any): AxiosPromise<Array<UserUserResponse>> {
            return localVarFp.apiUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * удалить пользователя
         * @summary удалить пользователя
         * @param {string} id Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdDelete(id: string, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить платеж по id.
         * @summary получить платеж по id.
         * @param {string} id Идентификатор
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdGet(id: string, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить платеж по id.
         * @summary получить платеж по id.
         * @param {string} id Идентификатор
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdNameGet(id: string, options?: any): AxiosPromise<RestSuccessMessage> {
            return localVarFp.apiUserIdNameGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * смена пароля пользователя.
         * @summary смена пароля пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserChangePasswordRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdPasswordPut(id: string, user: UserChangePasswordRequest, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserIdPasswordPut(id, user, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление пользователя.
         * @summary обновление пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserUpdateUserRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdPut(id: string, user: UserUpdateUserRequest, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserIdPut(id, user, options).then((request) => request(axios, basePath));
        },
        /**
         * создание пользователя.
         * @summary создание пользователя.
         * @param {UserCreateUserRequest} user Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost(user: UserCreateUserRequest, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserPost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * получить список всех старших операторов и менторов.
     * @summary получить список всех старших операторов и менторов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserDictionaryGet(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserDictionaryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список всех источников.
     * @summary получить список всех пользователей.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserGet(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить пользователя
     * @summary удалить пользователя
     * @param {string} id Идентификатор пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserIdDelete(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить платеж по id.
     * @summary получить платеж по id.
     * @param {string} id Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserIdGet(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить платеж по id.
     * @summary получить платеж по id.
     * @param {string} id Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserIdNameGet(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserIdNameGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * смена пароля пользователя.
     * @summary смена пароля пользователя.
     * @param {string} id Идентификатор пользователя
     * @param {UserChangePasswordRequest} user Данные для обновления пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserIdPasswordPut(id: string, user: UserChangePasswordRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserIdPasswordPut(id, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление пользователя.
     * @summary обновление пользователя.
     * @param {string} id Идентификатор пользователя
     * @param {UserUpdateUserRequest} user Данные для обновления пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserIdPut(id: string, user: UserUpdateUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserIdPut(id, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание пользователя.
     * @summary создание пользователя.
     * @param {UserCreateUserRequest} user Данные пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserPost(user: UserCreateUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}


