import { useContext, useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../context/useCases"
import { FiledType, Form } from "../../../components/form"
import { Menu } from "../../../components/menu"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { CalcParams } from "../../../../domain/calcparams/calcparams"

export const CalcParamsInfo = () => {

    let useCases = useContext(UseCasesContext)

    const [calcParams,setCalcParams] = useState<CalcParams | null>()



    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm + '.' + yyyy;
    }


    const users = useTypedSelector(({ user }) => {
        return user
    })

    useEffect(()=>{
        useCases?.calcParamsUseCase.ReadActualCalcParams().then((response: CalcParams | Error)=>{
            if (response instanceof Error) {

            } else {
                setCalcParams(response)
            }
        })
    },[])


    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-6 col-md-6">
                            <h4 className="fw-bold py-3 mb-4">Условия расчета</h4>
                        </div>
                        <div className="col-sm-6 col-md-6">
                            <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                                <div className="dt-buttons py-3 mb-4">
                                    <Link to={"/calc-params/edit/"} className="dt-button add-new btn btn-primary mb-3 mb-md-0" ><span>{!calcParams?"Создать":"Редактировать"}</span></Link>
                                </div>
                            </div>
                        </div>
                        {calcParams?<div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Актуальные условия расчета</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 mb-1">
                                        <div className="mb-3">
                                            <h6 className="mb-1">Ставка Новичок (до 4 мес.)</h6>
                                            <p>{calcParams.Junior()}</p>
                                        </div>
                                        <div className="mb-3">
                                            <h6 className="mb-1">Ставка Опытный (5-7 мес.)</h6>
                                            <p>{calcParams.Middle()}</p>
                                        </div>
                                        <div className="mb-3">
                                            <h6 className="mb-1">Ставка Профи (от 8 мес.)</h6>
                                            <p>{calcParams.Profi()}</p>
                                        </div>
                                        <div className="mb-3">
                                            <h6 className="mb-1">Обучение, руб</h6>
                                            <p>{calcParams.Study()}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>:<></>}
                    </div>
                </div>
            </div>
        </div>
    )
}
