export class Payment {
     
    private id: string; 
    private operatorId: string; 
    private start: Date; 
    private end: Date; 
    private studyAvans: boolean;
    private avans: number; 
    private workedHours: number; 
    private penalty: number; 
    private mainBonus: number; 
    private scoreboardBonus: number; 
    private groupBonus: number; 
    private bank: string; 
    private processedPayments: string[];
    private createdAt: Date; 
    private modifiedAt: Date;

    constructor( id: string, operatorId: string, start: Date, end: Date, studyAvans: boolean, avans: number, workedHours: number, penalty: number, mainBonus: number, scoreboardBonus: number, groupBonus: number, bank: string, processedPayments: string[], createdAt: Date, modifiedAt: Date,) {
        this.id = id;
        this.operatorId = operatorId;
        this.start = start;
        this.end = end;
        this.studyAvans = studyAvans;
        this.avans = avans;
        this.workedHours = workedHours;
        this.penalty = penalty;
        this.mainBonus = mainBonus;
        this.scoreboardBonus = scoreboardBonus;
        this.groupBonus = groupBonus;
        this.bank = bank;
        this.processedPayments = processedPayments;
        this.createdAt = createdAt;
        this.modifiedAt = modifiedAt;
        
    }

    
    public Id = (): string => {
        return this.id
    }
    public OperatorId = (): string => {
        return this.operatorId
    }
    public Start = (): Date => {
        return this.start
    }
    public End = (): Date => {
        return this.end
    }
    public StudyAvans = (): boolean => {
        return this.studyAvans
    }
    public Avans = (): number => {
        return this.avans
    }
    public WorkedHours = (): number => {
        return this.workedHours
    }
    public Penalty = (): number => {
        return this.penalty
    }
    public MainBonus = (): number => {
        return this.mainBonus
    }
    public ScoreboardBonus = (): number => {
        return this.scoreboardBonus
    }
    public GroupBonus = (): number => {
        return this.groupBonus
    }
    public Bank = (): string => {
        return this.bank
    }

    public ProcessedPayments = () => {
        return this.processedPayments
    }
    
    public CreatedAt = (): Date => {
        return this.createdAt
    }
    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }
}