import { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../context/useCases"
import { FiledType, Form, Field } from "../../components/form"
import { OperatorDayStat } from "../../../domain/operatorworktime/operator_work_time_day_stat"
import { LockAccess } from "tabler-icons-react"


export const WorkTimeModal = (props: { operatorDayStat: OperatorDayStat, operatorName: string, operatorLogin: string, close: () => void }) => {

    let useCases = useContext(UseCasesContext)

    const [form, setForm] = useState<
        {
            id: UniqueId,
            factWorkedHours: number,
            postWorkTimeHours: number,
            comment: string,
        }>({
            id: "",
            factWorkedHours: 0,
            postWorkTimeHours: 0,
            comment: "",
        })

    const hoursToSeconds = (hour: number) => {
        return hour * 3600
    }

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (form.id === "") {
            useCases?.tabelUseCase.CreateWorkTimeCorrection(props.operatorDayStat.getId(), hoursToSeconds(form.factWorkedHours), hoursToSeconds(form.postWorkTimeHours), form.comment, () => props.close())
        } else {
            useCases?.tabelUseCase.UpdateWorkTimeCorrection(form.id, props.operatorDayStat.getId(), hoursToSeconds(form.factWorkedHours), hoursToSeconds(form.postWorkTimeHours), form.comment, () => props.close())
        }
    }

    useEffect(() => {
        let correction = props.operatorDayStat.getCorrection()
        if (correction) {
            setForm({
                ...form,
                id: correction!.getId(),
                factWorkedHours: correction.getFactWorkedSeconds()! / 3600,
                postWorkTimeHours: correction.getPostWorkTimeSeconds()! / 3600,
                comment: correction.getComment(),
            })
        }
    }, [])

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }

    const formatDate = (day: Date) => {
        if (day.getFullYear() === 1) {
            return ""
        }
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm;
    }

    const pickColorForCorrectionStatus = (status: string) => {
        switch (status) {
            case "approved":
                return "#98FB98"
            case "declined":
                return "#fadadd"
            case "changed":
                return "#ffffbf"
        }
    }

    const drawStatus = () => {
        return <div style={{backgroundColor: pickColorForCorrectionStatus(props.operatorDayStat.getCorrection()?.getCorrectionStatus()!)}}>{props.operatorDayStat.getCorrection()?.getCorrectionStatusString()}</div>
    }

    if (useCases?.authUseCase.IsHr()) {
        return <div className="modal fade show" id="modalCenter" style={{ display: "block" }} aria-modal="true" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title mb-5 col-12" id="modalCenterTitle" style={{color: "red", display: "flex", justifyContent: "center"}} ><LockAccess size={100} color="red" /></h5>

                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { props.close() }}></button>
                </div>
            </div>
        </div>
    </div>
    }


    return <div className="modal fade show" id="modalCenter" style={{ display: "block" }} aria-modal="true" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="modalCenterTitle">Корректировки</h5>

                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { props.close() }}></button>
                </div>
                <div className="modal-body">
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <table className="table table-striped table-borderless mb-4">
                            <tbody className="table table-hover">
                                <tr>
                                    <td>Имя</td>
                                    <td>{props.operatorName}</td>
                                </tr>
                                <tr>
                                    <td>Статус</td>
                                    <td>{props.operatorDayStat.getCorrection()?drawStatus():<></>}</td>
                                </tr>
                                <tr>
                                    <td>ФОЧ</td>
                                    <td>{Math.round(props.operatorDayStat.getFactWorkedSeconds() / 3600)}</td>
                                </tr>
                                <tr>
                                    <td>Пост ч.</td>
                                    <td>{Math.round(props.operatorDayStat.getPostWorkedSeconds() / 3600)}</td>
                                </tr>
                                <tr>
                                    <td>Пост %</td>
                                    <td>{props.operatorDayStat.getPostPercent()}%</td>
                                </tr>
                                <tr>
                                    <td>Дата</td>
                                    <td>{formatDate(props.operatorDayStat.getDate())}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <Form
                        state={{
                            loading: false,
                            error: "",
                        }}

                        submit={submit}

                        fields={[
                            {
                                name: "factWorkedHours",
                                title: "Фактическое отработанное время",
                                placeholder: "Введите фактическое отработанное время",
                                value: form.factWorkedHours,
                                type: FiledType.Number,
                                required: true,
                            },
                            {
                                name: "postWorkTimeHours",
                                title: "Время постобработки",
                                placeholder: "Введите время постобработки",
                                value: form.postWorkTimeHours,
                                type: FiledType.Number,
                                required: true,
                            },
                            {
                                name: "comment",
                                title: "Комментарий",
                                placeholder: "Введите комментарий",
                                value: form.comment,
                                type: FiledType.TextArea,
                                required: true,
                            },
                        ]}
                        btnSmall={true}
                        submitBtnTitle={"Сохранить"}
                        updateForm={updateForm}
                    />
                </div>

            </div>
        </div>
    </div>
}