import { combineReducers } from "redux";
import loginReducer from './loginReducer';
import userReducer from './user';
import calcparamsReducer from './calcparams';
import paymentReducer from './payment';
import operatorReducer from './operator';
import tabelReducer from './leader_group'

const reducers = combineReducers({
    login: loginReducer,
    user: userReducer,
    calcParams: calcparamsReducer,
    payment: paymentReducer,
    operator: operatorReducer,
    tabel: tabelReducer,
})

export default reducers;
export type RootState = ReturnType<typeof reducers>;