export enum OperatorActionTypes {
    OPERATOR_REQUEST_SEND = 'operator_request_send',
    OPERATOR_ERROR = 'operator_error',
    OPERATOR_SUCCESS = 'operator_success',
    
    OPERATOR_CREATE_REQUEST = 'operator_create_request',
    OPERATOR_CREATE_SUCCESS = 'operator_create_success',
    OPERATOR_CREATE_ERROR = 'operator_create_error',

    OPERATOR_DELETE_REQUEST = 'operator_delete_request',
    OPERATOR_DELETE_SUCCESS = 'operator_delete_success',
    OPERATOR_DELETE_ERROR = 'operator_delete_error',

    OPERATOR_UPDATE_REQUEST = 'operator_update_request',
    OPERATOR_UPDATE_SUCCESS = 'operator_update_success',
    OPERATOR_UPDATE_ERROR = 'operator_update_error',
}