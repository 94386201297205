import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../context/useCases"
import { FiledType, Form } from "../../../components/form"
import { Menu } from "../../../components/menu"

export const CalcParamsUpdate = () => {
    const navigate = useNavigate();

    let useCases = useContext(UseCasesContext)
    const [form, setForm] = useState<{
        from: Date | null,
        study: number,
        profi: number,
        middle: number,
        junior: number
    }>({
        from: null,
        study: 0,
        profi: 0,
        middle: 0,
        junior: 0,
    })

   

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        useCases?.calcParamsUseCase.CreateCalcParams(form.from!, form.study, form.profi, form.middle, form.junior, () => { navigate(-1) })
    }

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }

    const readCalcParams = async () => {
        let response = await useCases?.calcParamsUseCase.ReadActualCalcParams()
        if (response instanceof Error) {
            
        } else {
 
            setForm({
                from: response?.From()!,
                study: response?.Study()!,
                profi: response?.Profi()!,
                middle: response?.Middle()!,
                junior: response?.Junior()!,
            })
        }
    }

    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm + '.' + yyyy;
    }



    useEffect(() => {
        readCalcParams()
    }, [])



    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Условия расчета /</span> Редактирование</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Редактирование</h5>
                            </div>
                            <div className="card-body">
                                <Form
                                    state={{
                                        loading: false,
                                        error: "",
                                    }}

                                    submit={submit}

                                    fields={[
                                        {
                                            name: "from",
                                            title: "Укажите с какого месяца начинает действовать",
                                            placeholder: "Выберите месяц",
                                            value: form.from,
                                            type: FiledType.Month,
                                        },
                                        {
                                            name: "junior",
                                            title: "Ставка Новичок",
                                            placeholder: "Укажите ставку Новичок",
                                            value: form.junior,
                                            type: FiledType.Number,
                                        },
                                        {
                                            name: "middle",
                                            title: "Ставка Опытный",
                                            placeholder: "Укажите ставку Опытный",
                                            value: form.middle,
                                            type: FiledType.Number,
                                        },
                                        {
                                            name: "profi",
                                            title: "Ставка Профи",
                                            placeholder: "Укажите ставку Профи",
                                            value: form.profi,
                                            type: FiledType.Number,
                                        },
                                        {
                                            name: "study",
                                            title: "Обучение, руб",
                                            placeholder: "Укажите ставку Обучение",
                                            value: form.study,
                                            type: FiledType.Number,
                                        },

                                    ]}
                                    btnSmall={true}
                                    submitBtnTitle={"Сохранить"}
                                    updateForm={updateForm}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
