import { Fragment, useContext, useEffect, useRef, useState } from "react";

import { ReportOperatorPaymentsOfMonth } from "../../../repository/api/generated";
import { UseCasesContext } from "../../../context/useCases";
import { WorkTimeModal } from "./work-time-modal";
import { Payment } from "../../../domain/payment/payment";
import { LeaderGroup } from "../../../domain/operatorworktime/leader_group";
import { OperatorDayStat } from "../../../domain/operatorworktime/operator_work_time_day_stat";
import { AcceptDeclineModal } from "./accept-decline-modal";

function useOutsideAlerter(ref: any, callBack: () => void) {
    useEffect(() => {

        function handleClickOutside(event: any) {
            if (ref?.current && !ref?.current.contains(event.target)) {
                callBack()
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [ref]);
}

export const TableRow = (props: { leaderGroup: LeaderGroup, daysInMonth: number, last: boolean }) => {
    const [showModal, setShowModal] = useState(false)
    const [editDayStat, setEditDayStat] = useState<{login: string, name: string, ds: OperatorDayStat} | null>()

    const rowRef = useRef<any>(null);
    let useCases = useContext(UseCasesContext)

    const formatDate = (day: Date) => {
        if (day.getFullYear() === 1) {
            return ""
        }
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm + '.' + yyyy.toString().substring(2);
    }

    useOutsideAlerter(rowRef, () => {
        //setShowModal(false)
    });

    const formatDateMonthAndYear = (input: string) => {
        let monthNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]
        let date = new Date(input)
        return <>{monthNames[date.getMonth()]}<br /> {date.getFullYear()}</>
    }

    const pickRole = (input: string) => {
        switch (input) {
            case "operator":
                return "Оператор"
            case "ayp":
                return "АУП"
        }
    }

    const drawCellWithButton = (type: string, value: number) => {
        /*
            if (value == 0) {
                return <div className="css_sd css_sd_nowrap" >{value!}</div>
            }
            if (payment!.processed_payments?.includes(type)) {
                return <div className="css_sd css_sd_nowrap" style={{backgroundColor: "#dde8cb" }}>{value!}</div>
            }
            return <div className="css_sd css_sd_nowrap" style={{backgroundColor: "#ffd7d7"}}>{value!} <br /> <a href="#" onClick={(e) => { e.preventDefault(); setPaymentProcessed(type) }}>Оплатить</a></div>
        */
    }

    const colorByValueCell = (value: number) => {
        if (value == 0) {
            return <div className="css_sd css_sd_nowrap" >{value!}</div>
        }

        return <div className="css_sd css_sd_nowrap" style={{ backgroundColor: "#ffd7d7" }}>{value!}</div>
    }

    const secondsToHours = (sec: number) => {
        return Math.round(sec / 60 / 60)
    }

    const pickDayStatForDay = (day: number, operatorDayStats: OperatorDayStat[]) => {
        let operatorDayStat: OperatorDayStat | null = null

        for(let i =0; i< operatorDayStats.length; i++) {
            let item = operatorDayStats[i];
            if (day == item.getDate().getDate()) {
                operatorDayStat = item;
                break
            }
        }

        return operatorDayStat;
    }

    const pickColorForCorrectionStatus = (status: string) => {
        switch (status) {
            case "approved":
                return "#98FB98"
            case "declined":
                return "#fadadd"
            case "changed":
                return "#ffffbf"
        }
    }

    return (
        <Fragment key={props.leaderGroup!.getLeaderName()}>
            <div key={props.leaderGroup!.getLeaderName()} className="css_tr" ref={rowRef} >
                <div className="css_sd css_sd_nowrap" style={{ backgroundColor: "#d6ffff" }}>{props.leaderGroup!.getLeaderName()}</div>
                <div className="css_sd css_sd_nowrap" style={{ backgroundColor: "#d6ffff" }}></div>
                <div className="css_sd css_sd_nowrap" style={{ backgroundColor: "#d6ffff" }}></div>
                <div className="css_sd css_sd_nowrap" style={{ backgroundColor: "#d6ffff" }}></div>
                {Array.from(Array(props.daysInMonth).keys()).map((date) => {
                    return <>
                    <div className="css_sd css_sd_nowrap" style={{ backgroundColor: "#d6ffff"}}></div>
                    <div className="css_sd css_sd_nowrap" style={{ backgroundColor: "#d6ffff"}}></div>
                    </>
                })}
            </div>
            {props.leaderGroup.getOperatorsTabel().map(ot => {
                return <div key={ot.getOperatorLogin()} className="css_tr" ref={rowRef} >
                    <div className="css_sd css_sd_nowrap" style={{backgroundColor: "#ffff"}}>{ot.getOperatorName()}</div>
                    <div className="css_sd css_sd_nowrap" style={{backgroundColor: "#ffff"}}>{secondsToHours(ot.getPayedSecondsSum())}</div>
                    <div className="css_sd css_sd_nowrap" style={{}}>{secondsToHours(ot.getFactWorkedSecondsSum())}</div>
                    <div className="css_sd css_sd_nowrap" style={{}}>{ot.getPostPercentage()}</div>
                    {Array.from(Array(props.daysInMonth).keys()).map((date) => {
                            let dayStat = pickDayStatForDay(date+1, ot.getDayStats())
                            if (dayStat === null) {
                                return <>
                                    <div className="css_sd css_sd_nowrap" style={{ backgroundColor: "#f7f7f7"}}></div>
                                    <div className="css_sd css_sd_nowrap" style={{ backgroundColor: "#f7f7f7"}}></div>
                                </>
                            }

                            if (dayStat.getCorrection() != null) {
                                return <>
                                    <div className="css_sd css_sd_nowrap" onClick={()=>{setEditDayStat({login: ot.getOperatorLogin(), name: ot.getOperatorName(), ds: dayStat!})}} style={{ cursor: "pointer", backgroundColor: pickColorForCorrectionStatus(dayStat.getCorrection()?.getCorrectionStatus()!)}}>{Math.round(dayStat?.getCorrection()?.getFactWorkedSeconds()!/3600)}</div>
                                    <div className="css_sd css_sd_nowrap" onClick={()=>{setEditDayStat({login: ot.getOperatorLogin(), name: ot.getOperatorName(), ds: dayStat!})}} style={{ cursor: "pointer", backgroundColor: pickColorForCorrectionStatus(dayStat.getCorrection()?.getCorrectionStatus()!)}}>{dayStat?.getPostPercent()}</div>
                                </>
                            }
                            return <>
                                <div className="css_sd css_sd_nowrap" onClick={()=>{setEditDayStat({login: ot.getOperatorLogin(), name: ot.getOperatorName(), ds: dayStat!})}} style={{ cursor: "pointer"}}>{Math.round(dayStat?.getFactWorkedSeconds()/3600)}</div>
                                <div className="css_sd css_sd_nowrap" onClick={()=>{setEditDayStat({login: ot.getOperatorLogin(), name: ot.getOperatorName(), ds: dayStat!})}} style={{ cursor: "pointer"}}>{dayStat?.getPostPercent()}</div>
                            </>
                        })}
                    
                </div>
            })}
            {editDayStat ? (!editDayStat.ds.getCorrection() || editDayStat.ds.getCorrection()?.getCorrectionStatus() !== "changed") ? <WorkTimeModal operatorDayStat={editDayStat.ds} operatorName={editDayStat.name}  operatorLogin={editDayStat.login} close={()=>{setEditDayStat(null)}} /> : <AcceptDeclineModal operatorDayStat={editDayStat.ds} operatorName={editDayStat.name}  operatorLogin={editDayStat.login} close={()=>{setEditDayStat(null)}} /> :<></>}
        </Fragment>
    )
}
//
