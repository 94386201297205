export enum LeaderGroupActionTypes {
    LEADER_GROUP_REQUEST_SEND = 'leader_group_request_send',
    LEADER_GROUP_ERROR = 'leader_group_error',
    LEADER_GROUP_SUCCESS = 'leader_group_success',
    
    LEADER_GROUP_CREATE_REQUEST = 'leader_group_create_request',
    LEADER_GROUP_CREATE_SUCCESS = 'leader_group_create_success',
    LEADER_GROUP_CREATE_ERROR = 'leader_group_create_error',

    LEADER_GROUP_DELETE_REQUEST = 'leader_group_delete_request',
    LEADER_GROUP_DELETE_SUCCESS = 'leader_group_delete_success',
    LEADER_GROUP_DELETE_ERROR = 'leader_group_delete_error',

    LEADER_GROUP_UPDATE_REQUEST = 'leader_group_update_request',
    LEADER_GROUP_UPDATE_SUCCESS = 'leader_group_update_success',
    LEADER_GROUP_UPDATE_ERROR = 'leader_group_update_error',
}