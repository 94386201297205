import { ReportsRepository } from "../../repository/api/report/report";
import { actionCreators } from "../../state";

export class ReportUseCases {
    private api: ReportsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: ReportsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadReport = async (from: Date, to: Date, operatorId?: string | undefined, bank?: string | undefined, role?: string | undefined, responsibleId?: number | undefined) => {
        let response = await this.api.ReadReport(from, to, operatorId, bank, role, responsibleId)
        return response
    }

    public ReadOperatorsForSearch = async (from: Date, to: Date) => {

        return await this.api.ReadOperatorsForSearch(from, to)
    }

    public ReadReportXlsx = async (from: Date, to: Date, operatorId?: string | undefined, bank?: string | undefined, role?: string | undefined) => {
        let response = await this.api.ReadReportXlsx(from, to, operatorId, bank, role)
        return response
    }

    public ReadSberZp = async (from: Date, to: Date, operatorId?: string | undefined, bank?: string | undefined, role?: string | undefined) => {
        let response = await this.api.ReadSberZp(from, to, operatorId, bank, role)
        return response
    }

    public ReadTinkoffZp = async (from: Date, to: Date, operatorId?: string | undefined, bank?: string | undefined, role?: string | undefined) => {
        let response = await this.api.ReadTinkoffZp(from, to, operatorId, bank, role)
        return response
    }

    public ManualyUpdate = async () => {
        let response = await this.api.ManualyUpdate()
        return response
    }

    public ReadResponsiblesForSearch = async () => {
        let response = await this.api.ReadResponsiblesForSearch()
        return response
    }

    public ReadLeaderNamesForSearch = async () => {
        let response = await this.api.ReadLeaderNamesForSearch()
        return response
    }

}