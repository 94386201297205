import { Dispatch } from "react"
import { CalcParamsActionTypes } from "../action-types";
import { CalcParamsActions } from "../actions"
import { CalcParams } from "../../domain/calcparams/calcparams";

export const SendCalcParamsListRequest = () => {
    return async (dispatch: Dispatch<CalcParamsActions>) => {
        dispatch({
            type: CalcParamsActionTypes.CALCPARAMS_REQUEST_SEND,
          });
    }
}

export const CalcParamsListSuccess = (list: CalcParams[]) => {
    return async (dispatch: Dispatch<CalcParamsActions>) => {
        dispatch({
            type: CalcParamsActionTypes.CALCPARAMS_SUCCESS,
            payload: list,
        });
    }
}

export const CalcParamsListError = (error:string) => {
    return async (dispatch: Dispatch<CalcParamsActions>) => {
        dispatch({
            type: CalcParamsActionTypes.CALCPARAMS_ERROR,
            payload: error,
        });
    }
}

export const CalcParamsCreateRequest = () => {
    return async (dispatch: Dispatch<CalcParamsActions>) => {
        dispatch({
            type: CalcParamsActionTypes.CALCPARAMS_CREATE_REQUEST,
          });
    }
}

export const CalcParamsCreateSuccess = (calcparams: CalcParams) => {
    return async (dispatch: Dispatch<CalcParamsActions>) => {
        dispatch({
            type: CalcParamsActionTypes.CALCPARAMS_CREATE_SUCCESS,
            payload: calcparams,
          });
    }
}

export const CalcParamsCreateError = (message: string) => {
    return async (dispatch: Dispatch<CalcParamsActions>) => {
        dispatch({
            type: CalcParamsActionTypes.CALCPARAMS_CREATE_ERROR,
            payload: message,
          });
    }
}


export const CalcParamsUpdateRequest = () => {
    return async (dispatch: Dispatch<CalcParamsActions>) => {
        dispatch({
            type: CalcParamsActionTypes.CALCPARAMS_UPDATE_REQUEST,
          });
    }
}

export const CalcParamsUpdateSuccess = (category: CalcParams) => {
    return async (dispatch: Dispatch<CalcParamsActions>) => {
        dispatch({
            type: CalcParamsActionTypes.CALCPARAMS_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const CalcParamsUpdateError = (message: string) => {
    return async (dispatch: Dispatch<CalcParamsActions>) => {
        dispatch({
            type: CalcParamsActionTypes.CALCPARAMS_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const CalcParamsDeleteRequest = () => {
    return async (dispatch: Dispatch<CalcParamsActions>) => {
        dispatch({
            type: CalcParamsActionTypes.CALCPARAMS_DELETE_REQUEST,
          });
    }
}

export const CalcParamsDeleteSuccess = (id: UniqueId) => {
    return async (dispatch: Dispatch<CalcParamsActions>) => {
        dispatch({
            type: CalcParamsActionTypes.CALCPARAMS_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const CalcParamsDeleteError = (message: string) => {
    return async (dispatch: Dispatch<CalcParamsActions>) => {
        dispatch({
            type: CalcParamsActionTypes.CALCPARAMS_DELETE_ERROR,
            payload: message,
          });
    }
}