import axios from "axios";
import { Operator } from "../../../domain/operator/operator";
import { Configuration, DomainOperatorsForSearch, DomainResponsibleForSearch, ReportApi, ReportReport, } from "../generated";
import { ReportInterface } from "../../../useCase/report/adapters/interface";

export class ReportsRepository implements ReportInterface {
    private service: ReportApi;

    constructor() {
        this.service = new ReportApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    formatDate = (date: Date) => {

        let month = '' + (date.getMonth() + 1)
        let day = '' + date.getDate()
        let year = date.getFullYear()

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    async ReadReport(from: Date, to: Date, operatorId?: UniqueId, bank?:string, role?:string, responsibleId?: number): Promise<ReportReport | Error> {
        try {
            let response = await this.service.apiReportStartEndGet(this.formatDate(from), this.formatDate(to), operatorId, role, bank, responsibleId, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data!
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadReportXlsx(from: Date, to: Date, operatorId?: UniqueId, bank?:string, role?:string, responsibleId?: number): Promise<File | Error> {
        try {
            let response = await this.service.apiReportOperatorsStartEndXlsxPost(this.formatDate(from), this.formatDate(to), operatorId, role, bank, responsibleId, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data!
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadTinkoffZp(from: Date, to: Date, operatorId?: UniqueId, bank?:string, role?:string,): Promise<File | Error> {
        try {
            let response = await this.service.apiReportOperatorsStartEndTinkoffPost(this.formatDate(from), this.formatDate(to), operatorId, role, bank, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data!
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadSberZp(from: Date, to: Date, operatorId?: UniqueId, bank?:string, role?:string,): Promise<File | Error> {
        try {
            let response = await this.service.apiReportOperatorsStartEndSberPost(this.formatDate(from), this.formatDate(to), operatorId, role, bank, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data!
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadOperatorsForSearch(from: Date, to: Date): Promise<DomainOperatorsForSearch[] | Error> {
        try {
            let response = await this.service.apiReportOperatorsStartEndGet(this.formatDate(from), this.formatDate(to), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data!
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ManualyUpdate(): Promise<DomainOperatorsForSearch[] | Error> {
        try {
            let response = await this.service.apiReportUpdateOperatorsPost({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data!
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }


    async ReadResponsiblesForSearch(): Promise<DomainResponsibleForSearch[] | Error> {
        try {
            let response = await this.service.apiReportResponsibleGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data!
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadLeaderNamesForSearch(): Promise<string[] | Error> {
        try {
            let response = await this.service.apiReportLeadernamesGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data!
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    
    
}