import { useContext, useState } from "react"
import { Menu } from "../../components/menu"
import { UseCasesContext } from "../../../context/useCases"

export const Settings = () => {
    const [success, setSuccess] = useState<boolean | null>(null)
    let useCases = useContext(UseCasesContext)
    
    const update = async () => {
        setSuccess(null)
        let response = await useCases?.reportUseCase.ManualyUpdate()
        if (response instanceof Error) {
            setSuccess(false)
        } else {
            setSuccess(true)
        }
    }
    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="col-sm-12 col-md-8">
                    {success == true ? <div className="alert alert-success" role="alert">Успешно</div> : success == false ? <div className="alert alert-danger" role="alert">Ошибка обновления</div>:<></>}
                    <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center" style={{marginLeft: "20px"}}>
                        <div className="dt-buttons py-3 mb-4">
                            <button className="dt-button add-new btn btn-primary mb-3 mb-md-0" onClick={()=>{update()}} ><span>Обновить данные</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}