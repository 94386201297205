import { DayCorrection } from "./day_correction";

export class OperatorDayStat {
    private id: UniqueId;
    private date: Date;
    private factWorkedSeconds: number;
    private payedSeconds: number;
    private postPercent: number;
    private postWorkedSeconds: number;
    private correction: DayCorrection | null; 

    constructor(id: UniqueId, date: Date, factWorkedSeconds: number, payedSeconds: number, postPercent: number, postWorkedSeconds: number, correction: DayCorrection | null) {
        this.id = id;
        this.date = date;
        this.factWorkedSeconds = factWorkedSeconds;
        this.payedSeconds = payedSeconds;
        this.postPercent = postPercent;
        this.postWorkedSeconds = postWorkedSeconds;
        this.correction = correction;
    }

    public getId(): UniqueId {
        return this.id;
    }

    public getDate(): Date {
        return this.date;
    }

    public getFactWorkedSeconds(): number {
        return this.factWorkedSeconds;
    }

    public getPayedSeconds(): number {
        return this.payedSeconds;
    }

    public getPostPercent(): number {
        return this.postPercent;
    }

    public getPostWorkedSeconds(): number {
        return this.postWorkedSeconds;
    }

    public getCorrection(): DayCorrection | null {
        return this.correction;
    }

}