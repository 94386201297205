import { Operator } from "../../domain/operator/operator";
import { OperatorActionTypes } from "../action-types";
import { OperatorActions } from "../actions";
import produce from 'immer';


interface OperatorState {
    loading: boolean | null;
    operators: Operator[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: OperatorState = {
    loading: null,
    operators: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: OperatorState = initialState, action: OperatorActions) => {
    switch (action.type) {
        case OperatorActionTypes.OPERATOR_REQUEST_SEND:
            state.loading = true;
            return state;
        case OperatorActionTypes.OPERATOR_SUCCESS:
            state.loading = false;
            state.operators = action.payload;
            return state;
        case OperatorActionTypes.OPERATOR_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case OperatorActionTypes.OPERATOR_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case OperatorActionTypes.OPERATOR_CREATE_SUCCESS:
            state.entityLoading = false
            state.operators!.push(action.payload)
            state.entityError = ""
            return state;
        case OperatorActionTypes.OPERATOR_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case OperatorActionTypes.OPERATOR_DELETE_SUCCESS:
            state.operators = state.operators!.filter((operators) => operators.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

export default reducer;
