export class User {
     
    private id: string; 
    private name: string; 
    private login: string; 
    private pass: string; 
    private role: string; 
    private isMentorId: number;
    private headOperatorId: number;
    private isActive: boolean;
    private createdAt: Date; 
    private modifiedAt: Date;

    constructor( id: string, name: string, login: string, pass: string, role: string, isMentorId: number, headOperatorId: number, isActive: boolean, createdAt: Date, modifiedAt: Date,) {
        this.id = id;
        this.name = name;
        this.login = login;
        this.pass = pass;
        this.role = role;
        this.isMentorId = isMentorId;
        this.headOperatorId = headOperatorId;
        this.isActive = isActive;
        this.createdAt = createdAt;
        this.modifiedAt = modifiedAt;
    }

    
    public Id = (): string => {
        return this.id
    }
    public Name = (): string => {
        return this.name
    }
    public Login = (): string => {
        return this.login
    }
    public Pass = (): string => {
        return this.pass
    }
    public Role = (): string => {
        return this.role
    }

    public IsMentorId = (): number => {
        return this.isMentorId
    }

    public HeadOperatorId = (): number => {
        return this.headOperatorId
    }

    public IsActive = (): boolean => {
        return this.isActive
    }

    public CreatedAt = (): Date => {
        return this.createdAt
    }
    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }
}