import { LeaderGroup } from "../../domain/operatorworktime/leader_group";
import { LeaderGroupActionTypes } from "../action-types";
import { LeaderGroupActions } from "../actions";
import produce from 'immer';


interface LeaderGroupState {
    loading: boolean | null;
    leader_groups: LeaderGroup[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: LeaderGroupState = {
    loading: null,
    leader_groups: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: LeaderGroupState = initialState, action: LeaderGroupActions) => {
    switch (action.type) {
        case LeaderGroupActionTypes.LEADER_GROUP_REQUEST_SEND:
            state.loading = true;
            return state;
        case LeaderGroupActionTypes.LEADER_GROUP_SUCCESS:
            state.loading = false;
            state.leader_groups = action.payload;
            return state;
        case LeaderGroupActionTypes.LEADER_GROUP_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case LeaderGroupActionTypes.LEADER_GROUP_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case LeaderGroupActionTypes.LEADER_GROUP_CREATE_SUCCESS:
            state.entityLoading = false
            state.leader_groups!.push(action.payload)
            state.entityError = ""
            return state;
        case LeaderGroupActionTypes.LEADER_GROUP_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case LeaderGroupActionTypes.LEADER_GROUP_UPDATE_SUCCESS:
            state.entityLoading = false
            let leaderGroups: LeaderGroup[] = []
            for (let index = 0; index < state.leader_groups!.length; index++) {
                const lg = state.leader_groups![index];
                if (lg.getLeaderName() == action.payload.getLeaderName()) {
                    leaderGroups.push(action.payload)
                } else {
                    leaderGroups.push(lg)
                }
                
            }
            state.leader_groups = leaderGroups;
            state.entityError = ""
            return state;
        default:
            return state;
    }
})

export default reducer;
