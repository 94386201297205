export class DayCorrection {
    private id: UniqueId;
    private operatorWorkTimeId: UniqueId;
    private factWorkedSeconds: number;
    private postWorkTimeSeconds: number;
    private correctionStatus: string;
    private comment: string;
    private createdAt: Date;
    private modifiedAt: Date;


    constructor(id: UniqueId, operatorWorkTimeId: UniqueId, factWorkedSeconds: number, postWorkTimeSeconds: number, correctionStatus: string, comment: string, createdAt: Date, modifiedAt: Date) {
        this.id = id;
        this.operatorWorkTimeId = operatorWorkTimeId;
        this.factWorkedSeconds = factWorkedSeconds;
        this.postWorkTimeSeconds = postWorkTimeSeconds;
        this.correctionStatus = correctionStatus;
        this.comment = comment;
        this.createdAt = createdAt;
        this.modifiedAt = modifiedAt;
    }

    public getId(): UniqueId {
        return this.id;
    }
    
    public getOperatorWorkTimeId(): UniqueId {
        return this.operatorWorkTimeId;
    }

    public getFactWorkedSeconds(): number {
        return this.factWorkedSeconds;
    }

    public getPostWorkTimeSeconds(): number {
        return this.postWorkTimeSeconds;
    }

    public getCorrectionStatus(): string {
        return this.correctionStatus;
    }

    public getCorrectionStatusString(): string {
        switch (this.correctionStatus) {
            case "approved":
                return "Подтверждено" 
            case "changed":
                return "Изменено" 
            case "declined":
                return "Изменения отклонены" 
        }
        return this.correctionStatus;
    }

    public getComment(): string {
        return this.comment;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public getModifiedAt(): Date {
        return this.modifiedAt;
    }

   

}