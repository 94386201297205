import axios from "axios";
import { Configuration, OperatorWorkTimeApi, OperatorWorkTimeLeaderGroup, OperatorWorkTimeOperatorConsalidatedStat, OperatorWorkTimeOperatorWorkTimeCorrectionsResponse, OperatorWorkTimeOperatorsDayStat, } from "../generated";
import { LeaderGroup } from "../../../domain/operatorworktime/leader_group";
import { OperatorConsalidatedStat } from "../../../domain/operatorworktime/operator_consalidated_stat";
import { OperatorDayStat } from "../../../domain/operatorworktime/operator_work_time_day_stat";
import { DayCorrection } from "../../../domain/operatorworktime/day_correction";

export class OperatorWorkTimesRepository {
    private service: OperatorWorkTimeApi;

    constructor() {
        this.service = new OperatorWorkTimeApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadMonthTabel(month: number, year: number, rgs: string[], logins: string[]): Promise<LeaderGroup[] | Error> {
        try {
            let response = await this.service.apiOperatorWorkTimeTabelMonthYearGet(month, year, rgs, logins, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let leaderGroups : LeaderGroup[] = []
                response.data?.map((lg: OperatorWorkTimeLeaderGroup) => {
                    let leaderGroup = this.toDomainLeaderGroup(lg);
                    leaderGroups.push(leaderGroup)
                })
                return leaderGroups
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadMonthTabelXlsx(month: number, year: number, rgs: string[], logins: string[]): Promise<string | Error> {
        try {
            let response = await this.service.apiOperatorWorkTimeTabelMonthYearXlsxGet(month, year, rgs, logins, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async CreateOperatorCorrection(operatorWorkTimeId: UniqueId, factWorkedSeconds: number, postWorkTimeSeconds: number, comment: string): Promise<LeaderGroup | Error> {
        try {
            let response = await this.service.apiOperatorWorkTimeCorrectionPost({operator_work_time_id: operatorWorkTimeId, fact_worked_seconds: factWorkedSeconds, post_work_time_seconds: postWorkTimeSeconds, comment: comment}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 201) {
               
                return this.toDomainLeaderGroup(response.data)
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async UpdateOperatorCorrection(correctionId: UniqueId, operatorWorkTimeId: UniqueId, factWorkedSeconds: number, postWorkTimeSeconds: number, comment: string): Promise<LeaderGroup | Error> {
        try {
            let response = await this.service.apiOperatorWorkTimeCorrectionIdPatch(correctionId, {operator_work_time_id: operatorWorkTimeId, fact_worked_seconds: factWorkedSeconds, post_work_time_seconds: postWorkTimeSeconds, comment: comment}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 201) {
               
                return this.toDomainLeaderGroup(response.data)
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadOperatorCorrectionById(correctionId: UniqueId): Promise<string | Error> {
        try {
            let response = await this.service.apiOperatorWorkTimeCorrectionIdGet(correctionId, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
               
                return ""
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ApproveOperatorCorrection(correctionId: UniqueId): Promise<LeaderGroup | Error> {
        try {
            let response = await this.service.apiOperatorWorkTimeCorrectionIdApprovePatch(correctionId, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 201) {
               
                return this.toDomainLeaderGroup(response.data)
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async DeclineOperatorCorrection(correctionId: UniqueId): Promise<LeaderGroup | Error> {
        try {
            let response = await this.service.apiOperatorWorkTimeCorrectionIdDeclinePatch(correctionId, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 201) {
               
                return this.toDomainLeaderGroup(response.data)
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }
    

    toDomainLeaderGroup(leaderGroup: OperatorWorkTimeLeaderGroup): LeaderGroup {
        let operatorConsalidatedStat: OperatorConsalidatedStat[] = [];
        leaderGroup.operators_tabel?.map((consStat: OperatorWorkTimeOperatorConsalidatedStat) => {
            operatorConsalidatedStat.push(this.toDomainConsalidatedStat(consStat));
        })
        return new LeaderGroup( leaderGroup.leader_name!, operatorConsalidatedStat);
    }

    toDomainConsalidatedStat(consStat: OperatorWorkTimeOperatorConsalidatedStat): OperatorConsalidatedStat {
        let dayStats: OperatorDayStat[] = [];
        consStat.day_stat?.map((dayStat: OperatorWorkTimeOperatorsDayStat)=> {
            dayStats.push(this.toDomainDayStat(dayStat))
        })
        return new OperatorConsalidatedStat(consStat.operator_name!, consStat.operator_login!, consStat.fact_worked_seconds_sum!, consStat.payed_seconds_sum!, consStat.post_percentage!, consStat.incall_seconds_sum!, consStat.post_work_time_seconds_sum!, dayStats);
    }

    toDomainDayStat(dayStat: OperatorWorkTimeOperatorsDayStat): OperatorDayStat {
        return new OperatorDayStat(dayStat.id!, new Date(dayStat.date!), dayStat.fact_worked_seconds!, dayStat.payed_seconds!, dayStat.post_percent!, dayStat.post_seconds!, this.toDomainCorrection(dayStat.correction!))
    }

    toDomainCorrection(correction: OperatorWorkTimeOperatorWorkTimeCorrectionsResponse | null): DayCorrection | null {
        if (!correction) {
            return null;
        }
        return new DayCorrection(correction?.id!, correction?.operator_work_time_id!, correction?.fact_worked_seconds!, correction?.post_work_time_seconds!, correction?.correction_status!, correction?.comment!, new Date(correction?.created_at!), new Date(correction?.modfied_at!))
    }
    
}