import { Menu } from "../../components/menu"

export const HeadsZp = () => {
    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <h1>В разработке 🏗️...</h1>
                </div>
            </div>
        </div>
    )
}