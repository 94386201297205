import { OperatorDayStat } from "./operator_work_time_day_stat";

export class OperatorConsalidatedStat {
    private operatorName: string;
    private operatorLogin: string;
    private factWorkedSecondsSum: number;
    private payedSecondsSum: number;
    private postPercentage: number;
    private inCallSecondsSum: number;
    private postWorkTimeSecondsSum: number;
    private dayStats: OperatorDayStat[];


    constructor(operatorName: string, operatorLogin: string, factWorkedSecondsSum: number, payedSecondsSum: number, postPercentage: number, inCallSecondsSum: number, postWorkTimeSecondsSum: number, dayStats: OperatorDayStat[]) {
        this.operatorName = operatorName;
        this.operatorLogin = operatorLogin;
        this.factWorkedSecondsSum = factWorkedSecondsSum;
        this.payedSecondsSum = payedSecondsSum;
        this.postPercentage = postPercentage;
        this.inCallSecondsSum = inCallSecondsSum;
        this.payedSecondsSum = payedSecondsSum;
        this.postWorkTimeSecondsSum = postWorkTimeSecondsSum;
        this.dayStats = dayStats;
    }

    public getOperatorName(): string {
        return this.operatorName;
    }

    public getOperatorLogin(): string {
        return this.operatorLogin;
    }

    public getFactWorkedSecondsSum(): number {
        return this.factWorkedSecondsSum;
    }

    public getPayedSecondsSum(): number {
        return this.payedSecondsSum;
    }

    public getPostPercentage(): number {
        return this.postPercentage;
    }

    public getInCallSecondsSum(): number {
        return this.inCallSecondsSum;
    }

    public getPostWorkTimeSecondsSum(): number {
        return this.postWorkTimeSecondsSum;
    }

    public getDayStats(): OperatorDayStat[] {
        return this.dayStats;
    }

}