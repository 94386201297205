export class CalcParams {
     
    private id: string; 
    private from: Date; 
    private junior: number; 
    private middle: number; 
    private profi: number; 
    private study: number; 
    private createdAt: Date; 
    private modifiedAt: Date;

    constructor( id: string, from: Date, junior: number, middle: number, profi: number, study: number, createdAt: Date, modifiedAt: Date,) {
        this.id = id;
        this.from = from;
        this.junior = junior;
        this.middle = middle;
        this.profi = profi;
        this.study = study;
        this.createdAt = createdAt;
        this.modifiedAt = modifiedAt;
        
    }

    
    public Id = (): string => {
        return this.id
    }
    public From = (): Date => {
        return this.from
    }
    public Junior = (): number => {
        return this.junior
    }
    public Middle = (): number => {
        return this.middle
    }
    public Profi = (): number => {
        return this.profi
    }
    public Study = (): number => {
        return this.study
    }
    public CreatedAt = (): Date => {
        return this.createdAt
    }
    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }
}