import { CalcParams } from "../../domain/calcparams/calcparams";
import { CalcParamsActionTypes } from "../action-types";
import { CalcParamsActions } from "../actions";
import produce from 'immer';


interface CalcParamsState {
    loading: boolean | null;
    calcparamss: CalcParams[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: CalcParamsState = {
    loading: null,
    calcparamss: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: CalcParamsState = initialState, action: CalcParamsActions) => {
    switch (action.type) {
        case CalcParamsActionTypes.CALCPARAMS_REQUEST_SEND:
            state.loading = true;
            return state;
        case CalcParamsActionTypes.CALCPARAMS_SUCCESS:
            state.loading = false;
            state.calcparamss = action.payload;
            return state;
        case CalcParamsActionTypes.CALCPARAMS_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case CalcParamsActionTypes.CALCPARAMS_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case CalcParamsActionTypes.CALCPARAMS_CREATE_SUCCESS:
            state.entityLoading = false
            state.calcparamss!.push(action.payload)
            state.entityError = ""
            return state;
        case CalcParamsActionTypes.CALCPARAMS_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case CalcParamsActionTypes.CALCPARAMS_DELETE_SUCCESS:
            state.calcparamss = state.calcparamss!.filter((calcparamss) => calcparamss.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

export default reducer;
